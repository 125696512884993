<template>
  <div class="page" style="text-align: left; padding:0 40px 0 40px">
    <div class="row">
      <div style="width: 99%" >
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox==false">Draco Natural Products, Inc.</b>
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox">{{printModel.form.BaseInfoEditPrintTitle}}</b>
            </div>
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>CERTIFICATE OF ANALYSIS</u></b>
            </div>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.form.BaseInfoProductName">
            <td style="width: 25%"><b>Product Name</b></td>
            <td style="width: 75%" colspan="3">
              {{ printModel.form.BaseInfoProductName }}
            </td>
          </tr>
          <tr v-show="printModel.form.ProductSubType">
            <td style="width: 25%"><b>Product SubType</b></td>
            <td>
              <span t-field="o.name" />{{ printModel.form.ProductSubType }}
            </td>
          </tr>
          <tr v-show="printModel.form.versionNumber">
            <td style="width: 25%"><b>Version Number</b></td>
            <td style="width: 75%" colspan="3">
              <span t-field="o.name" />{{ printModel.form.versionNumber }}
            </td>
          </tr>
          <!-- <tr v-show="printModel.form.BaseInfoCustomProductName">
            <td style="width: 25%"><b>Custom Product Name</b></td>
            <td style="width: 75%" colspan="3">
              {{ printModel.form.BaseInfoCustomProductName }}
            </td>
          </tr> -->
          <tr v-show="printModel.form.BaseInfoLot">
            <td style="width: 25%"><b>Lot #</b></td>
            <td style="width: 75%" colspan="3">{{ printModel.form.BaseInfoLot }}</td>
          </tr>
          <tr>
            <td v-show="printModel.form.BaseInfoItemCode" style="width: 25%">
              <b>Item Code:</b>
            </td>
            <td v-show="printModel.form.BaseInfoItemCode" style="width: 25%">
              {{ printModel.form.BaseInfoItemCode }}
            </td>
            <td
              v-show="printModel.form.BaseInfoCountryofOrigin"
              style="width: 25%"
            >
              <b>Country of Origin</b>
            </td>
            <td
              v-show="printModel.form.BaseInfoCountryofOrigin"
              style="width: 25%"
            >
              {{ printModel.form.BaseInfoCountryofOrigin }}
            </td>
          </tr>
          <tr
            v-show=" 
              printModel.form.BaseInfoCustomerPONumberCheckbox
            "
          >
            <td style="width: 25%"><b>Customer PO Number</b></td>
            <td style="width: 25%" colspan="3">
              <!-- <el-checkbox
                v-model="printModel.form.BaseInfoCustomerPONumberCheckbox"
              ></el-checkbox> -->
              <!-- {{ printModel.form.BaseInfoCustomerPONumberCheckbox }} -->
              {{ printModel.form.BaseInfoCustomerPONumber }}
            </td>
            <td style="width: 25%"></td>
            <td style="width: 25%"></td>
          </tr>

          <tr
            v-show=" 
              printModel.form.BaseInfoCustomerCodeCheckbox
            "
          >
            <td style="width: 25%"><b>Customer Code</b></td>
            <td style="width: 25%" colspan="3">
              <!-- <el-checkbox
                v-model="printModel.form.BaseInfoCustomerCodeCheckbox"
              ></el-checkbox> -->
              <!-- {{ printModel.form.BaseInfoCustomerCodeCheckbox }} -->
              {{ printModel.form.BaseInfoCustomerCode }}
            </td>
            <td style="width: 25%"></td>
            <td style="width: 25%"></td>
          </tr>

              <tr v-show="printModel.form.BaseInfoCOfOFAndCOfOE">
                <td style="width: 25%">
                  <b  >Country of Origin Feedstock and Country of Origin Extraction</b >
                </td>
                <td>
                  <!-- <el-checkbox
                    v-model="printModel.form.BaseInfoCOfOFAndCOfOE"
                    placeholder="Please Input"
                  ></el-checkbox> -->
                  <!-- {{ printModel.form.BaseInfoCOfOFAndCOfOE }} -->
                  {{ printModel.form.BaseInfoCOfOFAndCOfOEText }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoSAPNumber">
                <td style="width: 25%"><b>SAP Number</b></td>
                <td>
                  <!-- <el-checkbox
                    v-model="printModel.form.BaseInfoSAPNumber"
                    placeholder="Please Input"
                  ></el-checkbox> -->
                  <!-- {{ printModel.form.BaseInfoSAPNumber }} -->
                  {{ printModel.form.BaseInfoSAPNumberText }}
                </td>
              </tr>
          <tr>
            <td
              v-show="printModel.form.BaseInfoManufacturingDate"
              style="width: 25%"
            >
              <b>Manufacturing Date</b>
            </td>
            <td v-show="printModel.form.BaseInfoManufacturingDate" style="width: 25%">
              {{ formatTime(printModel.form.BaseInfoManufacturingDate,"yyyy-MM") }}
            </td>
            <td v-show="printModel.form.BaseInfoExpirationDate" style="width: 25%">
              <b>Expiration Date</b>
            </td>
            <td v-show="printModel.form.BaseInfoExpirationDate" style="width: 25%">
              {{ formatTime(printModel.form.BaseInfoExpirationDate,"yyyy-MM") }}
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.baseInfoCustomFieldList && printModel.baseInfoCustomFieldList.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.baseInfoCustomFieldList" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>
        <!-- General Specification -->
        <div style="font-size: 18px">
          <b><u>General Specification</u></b>
        </div>

        <!-- 遍历table -->
        <template v-if="isSingle().count > 1">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0">Common Name</th>
                <th v-show="printModel.inclNameArr.length > 0">INCI Name</th>
                <th v-show="printModel.botanicalNameArr.length > 0">Botanical Name</th>
                <!-- <th v-show="printModel.nativeHerbsArr.length > 0">
                  Major Constituents in Native Herbs
                </th> -->
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
                <th v-show="printModel.percentageArr.length > 0">Percentage </th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="printModel.commonNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.commonNameArr.length">{{
                    printModel.commonNameArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.inclNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.inclNameArr.length">{{
                    printModel.inclNameArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.botanicalNameArr.length > 0">
                  <div
                    v-show="i + 1 <= printModel.botanicalNameArr.length"
                    >{{ printModel.botanicalNameArr[i] }}</div
                  >
                </td>
                <!-- <td v-show="printModel.nativeHerbsArr.length > 0">
                  <template
                    v-show="i + 1 <= printModel.nativeHerbsArr.length"
                    >{{ printModel.nativeHerbsArr[i] }}</template
                  >
                </td> -->
                <td v-show="printModel.partUsedArr.length > 0">
                  <div v-show="i + 1 <= printModel.partUsedArr.length">{{
                    printModel.partUsedArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.percentageArr.length > 0">
                  <div v-show="i + 1 <= printModel.percentageArr.length">{{
                    printModel.percentageArr[i]
                  }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <div style="width: 100%" v-else-if="isSingle().count==1">
          <table style="width: 100%" cellspacing="0" class="tab">
            <tr>
              <td style="width: 25%">
                <b>{{isSingle().name}}</b>
              </td>
              <td style="width: 75%">
                <span  v-for="(item,val,index) in isSingle().arr" :key="index" >{{item}}<span v-if="val+1<isSingle().arr.length">, </span></span>
              </td>
            </tr>
          </table>
        </div>
        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.form.tableInput1" style="width:20%;">{{ printModel.form.tableInput1 }}</td>
                <td v-show="printModel.form.tableInput2" style="width:20%;">{{ printModel.form.tableInput2 }}</td>
                <td v-show="printModel.form.tableInput3" style="width:20%;">{{ printModel.form.tableInput3 }}</td>
                <td v-show="printModel.form.tableInput4" style="width:20%;">{{ printModel.form.tableInput4 }}</td>
                <td v-show="printModel.form.tableInput5" style="width:20%;">{{ printModel.form.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <table style="width: 98%">
          <tbody>
          <tr v-show="printModel.form.GeneralSpecNativeHerbs">
            <td style="width: 25%">
              <b>Major Constituents in Native Herbs:</b>
            </td>
            <td style="width: 75%">
              {{ printModel.form.GeneralSpecNativeHerbs }}
            </td>
          </tr>
          <tr v-show="printModel.form.GeneralSpecCarrier">
            <td style="width: 25%"><b>Carrier:</b></td>
            <td style="width: 75%">{{ printModel.form.GeneralSpecCarrier }}</td>
          </tr>
          <tr v-show="printModel.form.GeneralSpecPreservative">
            <td style="width: 25%"><b>Preservative:</b></td>
            <td style="width: 75%">
              {{ printModel.form.GeneralSpecPreservative }}
            </td>
          </tr>
          <tr v-show="printModel.form.GeneralSpecCustomRowKey1">
            <td style="width: 25%"><b>{{ printModel.form.GeneralSpecCustomRowKey1 }}:</b></td>
            <td style="width: 75%">
              {{
                printModel.form.GeneralSpecCustomRowValue1
              }}
            </td>
          </tr>
          <tr v-show="printModel.form.GeneralSpecCustomRowKey2">
            <td style="width: 25%"><b> {{ printModel.form.GeneralSpecCustomRowKey2 }}:</b></td>
            <td style="width: 75%">
             {{
                printModel.form.GeneralSpecCustomRowValue2
              }}
            </td>
          </tr>
          </tbody>
              <tbody
                v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr"
                :key="genIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 30%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 70%">
                    <span t-field="o.apperance">{{ item.customValue }}</span>
                  </td>
                </tr>
              </tbody>
        </table>
        <!-- Analysis -->
        <div>
          <!-- <b><u>Analysis</u></b> -->
        </div>
        <!-- <div style="height: 20px"></div> -->
        <div style="font-size: 18px">
          <b><u>Analysis</u></b>
        </div>
        <!-- <div>
          <p></p>
        </div> -->
        <table style="width: 98%">
          <tbody>
          <tr>
            <td style="width: 25%"><b>Test Item</b></td>
            <td style="width: 25%"><b>Specification</b></td>
            <td style="width: 25%"><b>Result</b></td>
            <td style="width: 25%"><b>Test Method</b></td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationBioactiveslevel ||
              printModel.form.ResultBioactiveslevel ||
              printModel.form.TestMethodBioactiveslevel
            "
          >
            <td style="width: 25%"><b>Bioactives level</b></td>
            <td style="width: 25%">
              ≥{{ printModel.form.SpecificationBioactiveslevel }}%
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultBioactiveslevel }}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodBioactiveslevel }}
            </td>
          </tr>
          
          <tr
            v-show="
              printModel.form.SpecificationCustomRowKey1 ||
              printModel.form.ResultCustomRowKey1 ||
              printModel.form.TestMethodCustomRowKey1
            "
          >
            <td style="width: 25%"><b> {{ printModel.form.SpecificationCustomRowKey1 }}</b></td>
            <td style="width: 25%">
             {{
                printModel.form.SpecificationCustomRowValue1
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.ResultCustomRowValue1
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.TestMethodCustomRowValue1
              }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationCustomRowKey2 ||
              printModel.form.ResultCustomRowKey2 ||
              printModel.form.TestMethodCustomRowKey2
            "
          >
            <td style="width: 25%"><b>{{ printModel.form.SpecificationCustomRowKey2 }}</b></td>
            <td style="width: 25%">
              {{
                printModel.form.SpecificationCustomRowValue2
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.ResultCustomRowValue2
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.TestMethodCustomRowValue2
              }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationCustomRowKey3 ||
              printModel.form.ResultCustomRowKey3 ||
              printModel.form.TestMethodCustomRowKey3
            "
          >
            <td style="width: 25%"><b>{{ printModel.form.SpecificationCustomRowKey3 }}</b></td>
            <td style="width: 25%">
              {{
                printModel.form.SpecificationCustomRowValue3
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.ResultCustomRowValue3
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.TestMethodCustomRowValue3
              }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationCustomRowKey4 ||
              printModel.form.ResultCustomRowKey4 ||
              printModel.form.TestMethodCustomRowKey4
            "
          >
            <td style="width: 25%"><b>{{ printModel.form.SpecificationCustomRowKey4 }}</b></td>
            <td style="width: 25%">
              {{
                printModel.form.SpecificationCustomRowValue4
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.ResultCustomRowValue4
              }}
            </td>
            <td style="width: 25%"> {{
                printModel.form.TestMethodCustomRowValue4
              }}
            </td>
          </tr>
          </tbody>
          
              <tbody
                v-for="(
                  item, analysisIndex
                ) in printModel.analysisCustomFieldAttr"
                :key="analysisIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 25%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue1 }}
                  </td>
                </tr>
              </tbody>
          <tbody>
          <tr
            v-show="
              printModel.form.SpecificationpH ||
              printModel.form.ResultpH  
            "
          >
            <td style="width: 25%"><b>pH</b></td>
            <td style="width: 25%">{{ printModel.form.SpecificationpH }}</td>
            <td style="width: 25%">{{ printModel.form.ResultpH }}</td>
            <td style="width: 25%">{{ printModel.form.TestMethodpH }}</td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationHeavyMetal ||
              printModel.form.ResultHeavyMetal  
            "
          >
            <td style="width: 25%"><b>Heavy Metal</b></td>
            <td style="width: 25%">
              {{ printModel.form.SpecificationHeavyMetal }}
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultHeavyMetal }} {{checkNumber(printModel.form.ResultHeavyMetal)?'ppm':''}}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodHeavyMetal }}
            </td>
          </tr>
          
          <tr v-show="printModel.form.SpecificationArsenic || printModel.form.ResultArsenic">
            <td style="width: 25%"><b>Arsenic</b></td>
            <td style="width: 25%">
              &lt;{{ printModel.form.SpecificationArsenic }} ppm
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultArsenic }}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodArsenic }}
            </td>
          </tr>
          <tr v-show="printModel.form.SpecificationCadmium || printModel.form.ResultCadmium">
            <td style="width: 25%"><b>Cadmium</b></td>
            <td style="width: 25%">
              &lt;{{ printModel.form.SpecificationCadmium }} ppm
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultCadmium }}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodCadmium }}
            </td>
          </tr>
          <tr v-show="printModel.form.SpecificationLead || printModel.form.ResultLead">
            <td style="width: 25%"><b>Lead</b></td>
            <td style="width: 25%">
              &lt;{{ printModel.form.SpecificationLead }} ppm
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultLead }}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodLead }}
            </td>
          </tr>
          <tr v-show="printModel.form.SpecificationMercury || printModel.form.ResultMercury">
            <td style="width: 25%"><b>Mercury</b></td>
            <td style="width: 25%">
              &lt;{{ printModel.form.SpecificationMercury }} ppm
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultMercury }}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodMercury }}
            </td>
          </tr>


          <tr
            v-show="
              printModel.form.SpecificationPesticides ||
              printModel.form.ResultPesticides  
            "
          >
            <td style="width: 25%"><b>Pesticides</b></td>
            <td style="width: 25%">
              {{ printModel.form.SpecificationPesticides }}
            </td>
            <td style="width: 25%">{{ printModel.form.ResultPesticides }}</td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodPesticides }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationTotalPlateCount ||
              printModel.form.ResultTotalPlateCount  
            "
          >
            <td style="width: 25%"><b>Total Plate Count</b></td>
            <td style="width: 25%">
              &lt;{{ printModel.form.SpecificationTotalPlateCount }} cfu/g
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultTotalPlateCount }} {{checkNumber(printModel.form.ResultTotalPlateCount)?'cfu/g':''}}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodTotalPlateCount }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationYeastandMold ||
              printModel.form.ResultYeastandMold  
            "
          >
            <td style="width: 25%"><b>Yeast and Mold</b></td>
            <td style="width: 25%">
              &lt;{{ printModel.form.SpecificationYeastandMold }} cfu/g
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultYeastandMold }}  {{checkNumber(printModel.form.ResultYeastandMold)?'cfu/g':''}}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodYeastandMold }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationEColi ||
              printModel.form.ResultEColi  
            "
          >
            <td style="width: 25%"><b>E. Coli</b></td>
            <td style="width: 25%">{{ printModel.form.SpecificationEColi }}</td>
            <td style="width: 25%">{{ printModel.form.ResultEColi }}</td>
            <td style="width: 25%">{{ printModel.form.TestMethodEColi }}</td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationSalmonella ||
              printModel.form.ResultSalmonella  
            "
          >
            <td style="width: 25%"><b>Salmonella</b></td>
            <td style="width: 25%">
              {{ printModel.form.SpecificationSalmonella }}
            </td>
            <td style="width: 25%">{{ printModel.form.ResultSalmonella }}</td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodSalmonella }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationStaphAureus ||
              printModel.form.ResultStaphAureus  
            "
          >
            <td style="width: 25%"><b>Staph Aureus</b></td>
            <td style="width: 25%">
              {{ printModel.form.SpecificationStaphAureus }}
            </td>
            <td style="width: 25%">{{ printModel.form.ResultStaphAureus }}</td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodStaphAureus }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationTotalColiform ||
              printModel.form.ResultTotalColiform  
            "
          >
            <td style="width: 25%"><b>Total Coliform</b></td>
            <td style="width: 25%" v-if="!printModel.form.SpecificationTotalColiform || printModel.form.SpecificationTotalColiform.toLowerCase()=='negative'">
              {{ printModel.form.SpecificationTotalColiform }}
            </td>
            <td style="width: 25%" v-else>
              &lt;{{ printModel.form.SpecificationTotalColiform }} MPN/g
            </td>
            <td style="width: 25%">
              {{ printModel.form.ResultTotalColiform }}
            </td>
            <td style="width: 25%">
              {{ printModel.form.TestMethodTotalColiform }}
            </td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationColor ||
              printModel.form.ResultColor  
            "
          >
            <td style="width: 25%"><b>Color</b></td>
            <td style="width: 25%">{{ printModel.form.SpecificationColor }}</td>
            <td style="width: 25%">{{ printModel.form.ResultColor }}</td>
            <td style="width: 25%">{{ printModel.form.TestMethodColor }}</td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationOdor ||
              printModel.form.ResultOdor  
            "
          >
            <td style="width: 25%"><b>Odor</b></td>
            <td style="width: 25%">{{ printModel.form.SpecificationOdor }}</td>
            <td style="width: 25%">{{ printModel.form.ResultOdor }}</td>
            <td style="width: 25%">{{ printModel.form.TestMethodOdor }}</td>
          </tr>
          <tr
            v-show="
              printModel.form.SpecificationTaste ||
              printModel.form.ResultTaste  
            "
          >
            <td style="width: 25%"><b>Taste</b></td>
            <td style="width: 25%">{{ printModel.form.SpecificationTaste }}</td>
            <td style="width: 25%">{{ printModel.form.ResultTaste }}</td>
            <td style="width: 25%">{{ printModel.form.TestMethodTaste }}</td>
          </tr>
          </tbody>
        </table>
       
        <div style="clear: both"></div>
        <!-- <div style="height: 20px"></div> -->
        <div style="font-size: 18px">
          <b><u>Note</u></b>
        </div>

        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div v-show="printModel.form.note1" style="margin-bottom: 10px;">
            <span><i>* Manufacturing Site: Shanghai Tianyuan Plant Products Company, Ltd.  No.18 Hexiang Rd, Baihe Town, Qingpu District, Shanghai.</i></span>
          </div>
          <!-- <div style="height: 10px" v-show="printModel.form.note1"></div> -->
          <div v-show="printModel.form.note2" style="margin-bottom: 10px;">
            <span><i>* The above listed product is non-irradiated product.</i></span>
          </div>
          <!-- <div style="height: 10px" v-show="printModel.form.note1"></div> -->
          <div v-show="printModel.form.note3" style="margin-bottom: 10px;">
            <span><i>* Disclaimer: This product is intended only for animal use and is not intended for human consumption.</i></span>
          </div>
          <!-- <div style="height: 10px" v-show="printModel.form.note1"></div> -->
          <div style="margin-bottom: 10px;">
            <span><i>* Color variation from batch to batch of the product may occur due to natural variations of raw material. </i></span>
          </div>
          <!-- <div style="height: 10px"></div> -->
          <div style="margin-bottom: 10px;">
            <span><i>* Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case.</i></span>
          </div>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <span><i>* The pH of liquid product should be tested in its 1% aqueous solution if the liquid product contains less than 50% water.</i></span>
          </div>
          <div style="margin-bottom: 10px;" v-for="(item, index) in printModel.newNoteArr" :key="index">
            <span>
              <i>
                * {{item.content}}
              </i>
            </span>
          </div>
        </div>

        <div style="height: 10px"></div>
        <div>
          <div style="float: left;">
            Quality Assurance- <span style="font-size:28px;font-family: 'Comic Sans MS', cursive;">{{ printModel.formData.updateName }}</span>
          </div>
          <div style="float: right;margin-right: 80px;">Date: {{ formatTime(printModel.formData.updateTime,"yyyy-MM-dd") }}</div>
        </div>
        <div style="height: 20px;clear:both;"></div>
        <div style="font-size: 14px;">
          <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
        </div>

        <div style="height: 10px;"></div>
        <div style="text-align: center">
          <b>
            <u>
              539 Parrott Street San Jose CA, 95112 Tel: 408-287-7871
              Fax:408-287-8838
            </u>
          </b>
        </div>
        <!-- <div style="height: 20px"></div> -->
        <div>
          <!-- <table
            style="width: 100%; text-align: center; font-size: 12px"
            border="1px solid gray"
            cellspacing="0"
            class="tab"
          >
            <tr>
              <td>
                <b>SECTION 2: HAZARDS IDENTIFICATION</b>
              </td>
            </tr>
          </table> -->
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
import base from "@/common/base.js";
export default {
  name: "CoaLiquid",
  mixins: [base],
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      manufacturingDate: "",
      expirationDate: "",
      loginName: localStorage.getItem("loginUserNameKey"),
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.inclNameArr.length,
        this.printModel.commonNameArr.length,
        this.printModel.botanicalNameArr.length,
        //this.printModel.nativeHerbsArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.form.tableInput1 || this.printModel.form.tableInput2 || this.printModel.form.tableInput3 || this.printModel.form.tableInput4 || this.printModel.form.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {
    formatTime(time,format){
      if(time){
        return base.methods.formatTime(time,format);
      }
      else{
        return base.methods.formatTime(new Date(),format);
      }
    },
    isSingle(){
        var count = 0;
        var name = "";
        var arr = [];
        if(this.printModel.commonNameArr.length>=1){
            name="Common Name";
            count = count+1;
            arr = this.printModel.commonNameArr;
        }
        if(this.printModel.inclNameArr.length>=1){
            name="INCI name";
            count = count+1;
            arr = this.printModel.inclNameArr;
        }
        if(this.printModel.botanicalNameArr.length>=1){
            name="Botanical Name";
            count = count+1;
            arr = this.printModel.botanicalNameArr;
        }
        if(this.printModel.partUsedArr.length>=1){
            name="Part Used";
            count = count+1;
            arr = this.printModel.partUsedArr;
        }
        let res =  {count,name,arr};
        return res
      },
    checkNumber(number){
      let firstStr=(number + "").substring(0,1)
      if(firstStr=='<' || firstStr=='≤'){
        number = number.substring(1)
      }
      var numReg = /^[0-9]+([.]{1}[0-9]+){0,1}$/
      var numRe = new RegExp(numReg)
      if (!numRe.test(number)) {
        return false
      }
      return true
    }
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if (userInfo) {
      localStorage.setItem("loginUserNameKey", userInfo.username);
    }
  },
  mounted() {
    console.log("====printModel====");
    console.log(this.printModel);
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>