<template>
  <div
    class="page"
    style="margin-top: -80px"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
  >
    <div id="printDiv" v-show="printShow">
      <CoaLiquid
        :printModel="this.$data"
        style="height-max: 1000px; width: 91vw;"
      ></CoaLiquid>
    </div>
    <div v-if="isShowAlonePrint" style="width: 21cm; margin-left: 25%">
      <div id="alonePrintDiv">
        <CoaLiquid
          :printModel="this.$data"
          style="height-max: 1000px"
        ></CoaLiquid>
      </div>
      <div style="margin-top: 50px">
        <el-button
          type="info"
          icon="el-icon-edit"
          @click="showUpdate()"
          v-if="isShowUpdate"
          >Update</el-button
        >
        <el-button
          type="success"
          icon="el-icon-printer"
          @click="aloneprintPage()"
          >Print</el-button
        >
        <el-button type="info" @click="closePage()">Exit</el-button>
      </div>
    </div>
    <center v-else>
      <div class="new-item-content" style="margin: 40px 0 40px 0">
        <!-- Base Info -->
        <div class="base-info">
          <div></div>
          <div>
            <div>
              <span>Edit Print Title：</span>

              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoEditPrintTitleCheckbox"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoEditPrintTitleCheckbox"
                  v-model="form.BaseInfoEditPrintTitle"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <!-- <div>
              <span>Custom Product Name：</span>
              <el-input
                v-model="form.BaseInfoCustomProductName"
                placeholder="Please Input"
              ></el-input>
            </div> -->
            <div>
              <span>Country of Origin：</span>
              <el-input
                v-model="form.BaseInfoCountryofOrigin"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Customer PO Number：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoCustomerPONumberCheckbox"
                  @change="selectPONumber"
                ></el-checkbox>
                <el-input
                  v-model="form.BaseInfoCustomerPONumber"
                  placeholder="Please Input"
                  style="width: 92%; margin-left: 5%"
                  v-if="isSelectPONumber"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Customer Code：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoCustomerCodeCheckbox"
                  @change="selectCustomerCode"
                ></el-checkbox>
                <el-input
                  v-model="form.BaseInfoCustomerCode"
                  placeholder="Please Input"
                  style="width: 92%; margin-left: 5%"
                  v-if="isSelectCustomerCode"
                ></el-input>
              </div>
            </div>
            <div style="margin: 0 0 0 0">
              <span style="width: 500px; margin-left: -200px"
                >Country of Origin Feedstock and Country of Origin
                Extraction：</span
              >
              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoCOfOFAndCOfOE"
                  placeholder="Please Input"
                ></el-checkbox>
                <el-input
                  v-model="form.BaseInfoCOfOFAndCOfOEText"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                  v-show="form.BaseInfoCOfOFAndCOfOE"
                ></el-input>
              </div>
            </div>
            <div>
              <span>SAP Number：</span>
              <div class="el-input" style="text-align: left">
                <el-checkbox v-model="form.BaseInfoSAPNumber"></el-checkbox>
                <el-input
                  v-model="form.BaseInfoSAPNumberText"
                  placeholder="Please Input"
                  style="margin-left: 10px; width: 575px"
                  v-show="form.BaseInfoSAPNumber"
                ></el-input>
              </div>
            </div>
            <div>
              <span>Manufacturing Date：</span>
              <el-date-picker
                v-model="form.BaseInfoManufacturingDate"
                placeholder="Please Select Date"
                type="month"
                @on-change="date = $event"
                @change="manufacturingDateChange"
              ></el-date-picker>
            </div>
            <div>
              <span>Expiration Date：</span>
              <el-date-picker
                v-model="form.BaseInfoExpirationDate"
                placeholder="Please Select Date"
                type="month"
                @on-change="date = $event"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <!-- General Specification -->
        <div class="base-info">
          <div>General Specification</div>
          <div>
            <div style="height: auto">
              <span>Common Name：</span>
              <!-- <el-input
                v-model="form.GeneralSpecCommonName"
                placeholder="Please Input"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in commonNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(commonNameArr, tag, i)"
                    @close="handleClose(tag, 'commonNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="commonNameInputVisible"
                    v-model="commonNameInputValue"
                    ref="commonNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonNameInputVisible',
                        'commonNameSaveTagInput'
                      )
                    "
                    >+ New CommonName</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>INCI name：</span>
              <!-- <el-input
                v-model="form.GeneralSpecINCIName"
                placeholder="Please Input"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(inclNameArr, tag, i)"
                    @close="handleClose(tag, 'inclNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ New InclName</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>Botanical Name：</span>
              <!-- <el-input
                v-model="form.GeneralSpecBotanicalName"
                placeholder="Please Input"
              ></el-input> -->

              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(botanicalNameArr, tag, i)"
                    @close="handleClose(tag, 'botanicalNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ New botanicalName</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>Major Constituents in Native Herbs：</span>

              <el-input
                v-model="form.GeneralSpecNativeHerbs"
                placeholder="Please Input"
              ></el-input>

              <!-- <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in nativeHerbsArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(nativeHerbsArr, tag, i)"
                    @close="handleClose(tag, 'nativeHerbsArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="nativeHerbsInputVisible"
                    v-model="nativeHerbsInputValue"
                    ref="nativeHerbsSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'nativeHerbsInputValue',
                        'nativeHerbsArr',
                        'nativeHerbsInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'nativeHerbsInputValue',
                        'nativeHerbsArr',
                        'nativeHerbsInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="nativeHerbsD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'nativeHerbsInputVisible',
                        'nativeHerbsSaveTagInput'
                      )
                    "
                    >+ New nativeHerbs</el-button
                  >
                </div>
              </div> -->
            </div>
            <div style="height: auto">
              <span>Percentage ：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in percentageArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(percentageArr, tag, i)"
                    @close="handleClose(tag, 'percentageArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="percentageInputVisible"
                    v-model="percentageInputValue"
                    ref="percentageSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'percentageInputVisible',
                        'percentageSaveTagInput'
                      )
                    "
                    >+ New Percentage</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>Part Used：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in partUsedArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(partUsedArr, tag, i)"
                    @close="handleClose(tag, 'partUsedArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="partUsedInputVisible"
                    v-model="partUsedInputValue"
                    ref="partUsedSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="partUsedD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                    "
                    >+ New Part Used</el-button
                  >
                </div>
              </div>
            </div>

            <div style="height: auto">
              <div style="display: inline-block; text-align: left">
                <table style="border: 1px solid #eee">
                  <tr>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="form.tableInput1"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="form.tableInput2"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="form.tableInput3"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="form.tableInput4"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="form.tableInput5"
                      ></el-input>
                    </td>
                    <td>
                      <el-button
                        type="primary"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(1, 0)"
                        >+</el-button
                      >
                    </td>
                  </tr>
                  <tr
                    v-for="(item, tableInputIndex) in tableInputCustomFieldAttr"
                    :key="tableInputIndex + 1"
                  >
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput1"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput2"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput3"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput4"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput5"
                      ></el-input>
                    </td>
                    <td>
                      <el-button
                        type="primary"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(1, 0)"
                        >+</el-button
                      >
                      <el-button
                        type="danger"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(0, tableInputIndex)"
                        >-</el-button
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div>
              <span>Carrier：</span>
              <el-input
                v-model="form.GeneralSpecCarrier"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Preservative：</span>
              <el-input
                v-model="form.GeneralSpecPreservative"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey1"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue1"
                  class="custom-row"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
            <div>
              <span
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey2"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue2"
                  class="custom-row"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
            <div>
              <span style="margin-left: 48px"
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey3"
                  class="custom-row"
                  placeholder="Please Input"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue3"
                  class="custom-row"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button
              >
            </div>

            <div
              v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
              :key="genSpecIndex + 1"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="Please Name Input"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, genSpecIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(0, genSpecIndex)"
                >-</el-button
              >
            </div>
          </div>
        </div>
        <!-- Analysis -->
        <div class="base-info">
          <div>Analysis</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 13%" class="FontWeight">Test Item</td>
                <td style="width: 29%" class="FontWeight">Specification</td>
                <td style="width: 29%" class="FontWeight">Result</td>
                <td style="width: 29%" class="FontWeight">Test Method</td>
              </tr>
              <tr>
                <td class="FontWeight">Bioactives level</td>
                <td>
                  <el-input
                    v-model="form.SpecificationBioactiveslevel"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">≥</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">%</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultBioactiveslevel"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodBioactiveslevel"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                    auto-complete=""
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue3"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue4"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button
                >
              </tr>

              <tr
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="Please Input"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndexEhxto)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndexEhxto)"
                  >-</el-button
                >
              </tr>
              <tr>
                <td class="FontWeight">pH</td>
                <td>
                  <el-input
                    v-model="form.SpecificationpH"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultpH"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodpH"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Heavy Metal</td>
                <td>
                  <el-input
                    v-model="form.SpecificationHeavyMetal"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultHeavyMetal"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodHeavyMetal"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">Arsenic</td>
                <td>
                  <el-input
                    v-model="form.SpecificationArsenic"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultArsenic"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodArsenic"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Cadmium</td>
                <td>
                  <el-input
                    v-model="form.SpecificationCadmium"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCadmium"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCadmium"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Lead</td>
                <td>
                  <el-input
                    v-model="form.SpecificationLead"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultLead"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodLead"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Mercury</td>
                <td>
                  <el-input
                    v-model="form.SpecificationMercury"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMercury"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMercury"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">Pesticides</td>
                <td>
                  <el-input
                    v-model="form.SpecificationPesticides"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultPesticides"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodPesticides"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Total Plate Count</td>
                <td>
                  <el-input
                    v-model="form.SpecificationTotalPlateCount"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalPlateCount"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalPlateCount"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Yeast and Mold</td>
                <td>
                  <el-input
                    v-model="form.SpecificationYeastandMold"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultYeastandMold"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodYeastandMold"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">E. Coli</td>
                <td>
                  <el-input
                    v-model="form.SpecificationEColi"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultEColi"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodEColi"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Salmonella</td>
                <td>
                  <el-input
                    v-model="form.SpecificationSalmonella"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultSalmonella"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodSalmonella"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Staph Aureus</td>
                <td>
                  <el-input
                    v-model="form.SpecificationStaphAureus"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultStaphAureus"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodStaphAureus"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Total Coliform</td>
                <td>
                  <el-input
                    @blur="checkSpecificationTotalColiforms()"
                    v-model="form.SpecificationTotalColiform"
                    placeholder="Please Input"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">MPN/g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalColiform"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalColiform"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Color</td>
                <td>
                  <el-input
                    v-model="form.SpecificationColor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultColor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodColor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Odor</td>
                <td>
                  <el-input
                    v-model="form.SpecificationOdor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultOdor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodOdor"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">Taste</td>
                <td>
                  <el-input
                    v-model="form.SpecificationTaste"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTaste"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTaste"
                    placeholder="Please Input"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <NoteTableData :baseInfo="this.$data"></NoteTableData>

        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top: 0px">
            <el-input
              v-model="newNote"
              placeholder="Please Input"
              style="width: 600px; margin-bottom: 10px; margin-right: 10px"
            ></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>
        <div class="base-info">
          <div>Note:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              *
              <el-checkbox v-model="form.note1">
                Manufacturing Site: Shanghai Tianyuan Plant Products Company,
                Ltd. No.18 Hexiang Rd, Baihe Town, Qingpu District, Shanghai.
              </el-checkbox>
            </div>
            <div>
              *
              <el-checkbox v-model="form.note2">
                The above listed product is non-irradiated product.
              </el-checkbox>
            </div>
            <div>
              *
              <el-checkbox v-model="form.note3">
                This product is intended only for animal use and is not intended
                for human consumption.
              </el-checkbox>
            </div>
            <div>
              * Color variation from batch to batch of the product may occur due
              to natural variations of raw material.
            </div>
            <div style="height: 60px; line-height: 20px">
              * Some natural sediment may occur upon standing, that does not
              compromise the quality of the product; please warm to ambient
              temperature and mix well before use in such case.
            </div>
            <div style="height: 40px; line-height: 20px">
              * The pH of liquid product should be tested in its 1% aqueous
              solution if the liquid product contains less than 50% water.
            </div>
            <div
              v-for="(item, index) in newNoteArr"
              :key="index"
              style="margin-top: 0px"
            >
              <span style="font-weight: 600">* {{ item.content }}</span>
              <el-button type="text" @click="deleteNewNoteTableClick(index)">
                <i
                  style="color: red; font-size: 20px"
                  class="el-icon-delete"
                ></i>
              </el-button>
            </div>
          </div>
        </div>
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('ContinueEdit')">Save</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="inventoryDialogVisible = true">Upload to library</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="updateBySpec()">COA Update</el-button>
          <el-button @click="back">Back Coa</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">Print</el-button>
        </div>
      </div>
    </center>

    <el-dialog
      title="Tips"
      :visible.sync="inventoryDialogVisible"
      width="30%"
      style="text-align:left;"
    >
      <span>Please select the type to save</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addToLibrary(1)">Inventory</el-button>
        <el-button type="primary" @click="addToLibrary(2)">Sample Inventory</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import base from "@/common/base.js";
import printJS from "print-js";
import CoaLiquid from "../PrintProductTypeModule/COA-L";
import NoteTableData from "../NoteTableData";
export default {
  props: { baseInfoItemCodeDetail: {},baseinfoCustom:{} },
  mixins: [base],
  components: { CoaLiquid, NoteTableData },
  data() {
    return {
      inventoryDialogVisible: false,
      addRevNumber: false,
      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,

      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameD: false,

      nativeHerbsArr: [],
      nativeHerbsInputVisible: "",
      nativeHerbsInputValue: "",
      nativeHerbsD: false,

      percentageArr: [],
      percentageInputVisible: "",
      percentageInputValue: "",
      percentageD: false,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedD: false,

      baseInfoCustomFieldList:[],
      form: {
        baseInfoCustomField:'',
        libraryRevNumber: 0,
        libraryCreateTime: "",
        revNumber: 1,
        versionNumber: "",
        revDate: [],
        noteTableData: "",
        BaseInfoEditPrintTitle: "",
        BaseInfoEditPrintTitleCheckbox: false,
        productType: "",
        oldProductType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        ProductSubType: "",
        BaseInfoLot: "",
        BaseInfoCustomProductName: "",
        BaseInfoCountryofOrigin: "China",
        BaseInfoCustomerPONumberCheckbox: "", //Checkbox
        BaseInfoCustomerPONumber: "",
        BaseInfoCustomerCodeCheckbox: "", //Checkbox
        BaseInfoCustomerCode: "",
        BaseInfoCOfOFAndCOfOE: false, //Checkbox
        BaseInfoCOfOFAndCOfOEText: "",
        BaseInfoSAPNumber: false, //Checkbox
        BaseInfoSAPNumberText: "",
        BaseInfoManufacturingDate: "",
        BaseInfoExpirationDate: "",

        GeneralSpecCommonName: "",
        GeneralSpecINCIName: "",
        GeneralSpecBotanicalName: "",
        GeneralSpecNativeHerbs: "",
        GeneralSpecPartUsed: "",
        GeneralSpecPercentage: "",
        GeneralSpecCarrier: "",
        GeneralSpecPreservative: "",
        GeneralSpecCustomRowKey1: "",
        GeneralSpecCustomRowValue1: "",
        GeneralSpecCustomRowKey2: "",
        GeneralSpecCustomRowValue2: "",
        GeneralSpecCustomRowKey3: "",
        GeneralSpecCustomRowValue3: "",

        SpecificationBioactiveslevel: "",
        SpecificationpH: "",
        SpecificationHeavyMetal: "Conforms to USP<231>",
        SpecificationPesticides: "Conforms to USP<565>",
        SpecificationTotalPlateCount: "",
        SpecificationYeastandMold: "100",
        SpecificationEColi: "",
        SpecificationSalmonella: "",
        SpecificationStaphAureus: "",
        SpecificationTotalColiform: "",
        SpecificationColor: "",
        SpecificationOdor: "Compares to standard",
        SpecificationTaste: "Compares to standard",
        SpecificationCustomRowKey1: "",
        SpecificationCustomRowValue1: "",
        SpecificationCustomRowKey2: "",
        SpecificationCustomRowValue2: "",
        SpecificationCustomRowKey3: "",
        SpecificationCustomRowValue3: "",
        SpecificationCustomRowKey4: "",
        SpecificationCustomRowValue4: "",

        ResultBioactiveslevel: "",
        ResultpH: "",
        ResultHeavyMetal: "<10",
        ResultPesticides: "Conforms to USP<565>",
        ResultTotalPlateCount: "100",
        ResultYeastandMold: "100",
        ResultEColi: "",
        ResultSalmonella: "",
        ResultStaphAureus: "",
        ResultTotalColiform: "",
        ResultColor: "",
        ResultOdor: "Compares to standard",
        ResultTaste: "Compares to standard",
        ResultCustomRowKey1: "",
        ResultCustomRowValue1: "",
        ResultCustomRowKey2: "",
        ResultCustomRowValue2: "",
        ResultCustomRowKey3: "",
        ResultCustomRowValue3: "",
        ResultCustomRowKey4: "",
        ResultCustomRowValue4: "",

        TestMethodBioactiveslevel: "",
        TestMethodpH: "USP<791>",
        TestMethodHeavyMetal: "USP<231>",
        TestMethodPesticides: "USP<565>",
        TestMethodTotalPlateCount: "FDA-BAM",
        TestMethodYeastandMold: "FDA-BAM",
        TestMethodEColi: "FDA-BAM",
        TestMethodSalmonella: "FDA-BAM",
        TestMethodStaphAureus: "FDA-BAM",
        TestMethodTotalColiform: "FDA-BAM",
        TestMethodColor: "Visual",
        TestMethodOdor: "Organoleptic",
        TestMethodTaste: "Organoleptic",
        TestMethodCustomRowKey1: "",
        TestMethodCustomRowValue1: "",
        TestMethodCustomRowKey2: "",
        TestMethodCustomRowValue2: "",
        TestMethodCustomRowKey3: "",
        TestMethodCustomRowValue3: "",
        TestMethodCustomRowKey4: "",
        TestMethodCustomRowValue4: "",

        SpecificationArsenic: "",
        SpecificationCadmium: "",
        SpecificationLead: "",
        SpecificationMercury: "",

        ResultArsenic: "",
        ResultCadmium: "",
        ResultLead: "",
        ResultMercury: "",

        TestMethodArsenic: "",
        TestMethodCadmium: "",
        TestMethodLead: "",
        TestMethodMercury: "",

        genSpecCustomField: "",
        analysisCustomField: "",
        note1: false,
        note2: false,
        note3: false,

        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
        tableInputCustomField: ""
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        jsonValue: {},
        updateName: "",
        updateTime: base.methods.getLocalNowTime(),
        createTime: base.methods.getLocalNowTime()
      },
      loading: false,
      isSelectPONumber: false,
      isSelectCustomerCode: false,
      printShow: false,
      printObj: {
        id: "printDiv", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "" // 打印头部文字
      },
      createTime: base.methods.getLocalNowTime(),

      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: ""
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: ""
      },
      isShowAlonePrint: false,
      isShowUpdate: false,
      shelflifeNumber: 0,
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason: "",
          approvedBy: "",
          isComplete: false
        }
      ],
      newNoteArr: [],
      newNote: ""
    };
  },
  methods: {
    back() {
      this.$router.push({ path: "/coa-items" });
    },
    showUpdate() {
      this.isShowAlonePrint = false;
      this.$parent.isShowAlonePrint = false;
    },
    closePage() {
      if (navigator.userAgent.indexOf("MSIE") > 0) {
        // close IE
        if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
          window.opener = null;
          window.close();
        } else {
          window.open("", "_top");
          window.top.close();
        }
      } else {
        // close chrome;It is effective when it is only one.
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    },
    checkSpecificationTotalColiforms() {
      this.form.SpecificationTotalColiform =
        this.form.SpecificationTotalColiform.toLowerCase() == "negative"
          ? this.form.SpecificationTotalColiform
          : this.form.SpecificationTotalColiform.replace(/[^\d.]|/g, "");
    },
    updateBySpec() {
      this.$confirm(
        "Are you sure synchronize the spec content. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning"
        }
      ).then(() => {
        this.loading = true;
        this.$api.handleSpec
          .getDetailByItemCode(this.$parent.params.BaseInfoItemCode)
          .then(res => {
            if (res.jsonValue != undefined) {
              console.log("getDetailByItemCode==res");
              console.log(res);
              this.$parent.params.BaseInfoProductName = res.productName;
              this.$parent.revDateTime = base.methods.getLocalNowTime();
              let jsonValue = JSON.parse(res.jsonValue);
              console.log("revNumber===" + jsonValue.revNumber);
              this.$parent.params.revNumber = jsonValue.revNumber;
              console.log(
                "this.$parent.params.revNumber===" +
                  this.$parent.params.revNumber
              );

              this.getBaseInfoDetail(jsonValue);
              this.handleNew("ContinueEdit");
            }
            this.loading = false;
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
          });
      });
    },
    handleNew(type) {
      let params = { ...this.$route.query };
      console.log("=============");
      console.log(this.$parent.params);

      this.form.noteTableData = JSON.stringify(this.noteTableData);
      this.form.newNoteArr = JSON.stringify(this.newNoteArr);
      // let revNumber = 0
      // this.noteTableData.forEach(item=>{
      //   if(item.isComplete){
      //     revNumber ++
      //   }
      // })
      // this.form.revNumber = revNumber

      this.form.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
      this.form.productType = this.$parent.params.productType;
      this.form.oldProductType = this.$parent.params.oldProductType;
      this.form.ProductSubType = this.$parent.params.ProductSubType;
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.revNumber = this.$parent.params.revNumber;

      this.form.GeneralSpecINCIName = this.inclNameArr.join("_");
      this.form.GeneralSpecCommonName = this.commonNameArr.join("_");
      this.form.GeneralSpecBotanicalName = this.botanicalNameArr.join("_");
      //this.form.GeneralSpecNativeHerbs = this.nativeHerbsArr.join("_");
      this.form.GeneralSpecPartUsed = this.partUsedArr.join("_");
      this.form.GeneralSpecPercentage = this.percentageArr.join("_");

      this.form.genSpecCustomField = JSON.stringify(
        this.genSpecCustomFieldAttr
      );
      this.form.analysisCustomField = JSON.stringify(
        this.analysisCustomFieldAttr
      );
      this.form.tableInputCustomField = JSON.stringify(
        this.tableInputCustomFieldAttr
      );

      let jsonStr = JSON.stringify(this.form);
      console.log(jsonStr);
      this.formData.productName = this.form.BaseInfoProductName;
      this.formData.itemCode = this.form.BaseInfoItemCode;
      this.formData.lot = this.form.BaseInfoLot;
      this.formData.jsonValue = jsonStr;
      console.log(this.formData);
      if (params.id) {
        this.form.revDate.push(this.$parent.revDateTime);
        jsonStr = JSON.stringify(this.form);
        this.formData.jsonValue = jsonStr;
        // this.formData.id = params.id;
        this.$api.coa
          .editCoaUrl(this.formData)
          .then(() => {
            this.$message({
              type: "success",
              message: "SuccessFul"
            });
            // this.$router.push({
            //   path: "coa-items",
            // });
            this.$parent.revDateTime = base.methods.getLocalNowTime();
            this.reloadPage(type);
            // this.addToLibrary(this.formData.id)
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err
            });
            console.log(err);
          });
      } else {
        this.$api.coa
          .addCoaUrl(this.formData)
          .then(res => {
            this.$message({
              type: "success",
              message: "SuccessFul"
            });
            console.log("提交返回成功");
            console.log(res);
            this.$parent.revDateTime = base.methods.getLocalNowTime();
            if (type == 1 || type == 2) {
              this.batchAddLibrary(res, type);
            }
            
            this.$confirm("Return to list page, Continue?", "Tips", {
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
              distinguishCancelAndClose: true,
              type: "warning"
            }).then(() => {
              this.$router.push({
                path: "coa-items"
              });
            });
            // this.$router.push({
            //   path: "coa-items",
            // });
            // this.reloadPage(type);
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err
            });
            console.log(err);
          });
      }
    },
    reloadPage(type) {
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "coa-item",
          query: { productType: "Powder" }
        });
        this.$router.go(0);
      }
    },
    getDetailById(params) {
      this.$parent.loading = true;
      this.$api.coa
        .handleCoaTableUrl(params)
        .then(data => {
          console.log("data");
          console.log(data);
          this.formData = data;
          this.createTime = this.formatTime(data.createTime, "yyyy-MM-dd");
          this.form = JSON.parse(data.jsonValue);

          if (this.form.newNoteArr == undefined) {
            this.form.newNoteArr = [];
            this.newNoteArr = [];
          } else {
            this.newNoteArr = JSON.parse(this.form.newNoteArr);
          }

          if (this.form.noteTableData) {
            this.noteTableData = JSON.parse(this.form.noteTableData);
          }
          if (this.form.revNumber == undefined) {
            this.form.revNumber = 0;
          }
          if (this.form.revDate == undefined) {
            this.form.revDate = [];
          }
          console.log("this.form.revDate");
          console.log(this.form.revDate);

          console.log("this.form.revNumber");
          console.log(this.form.revNumber);

          if (this.form.BaseInfoManufacturingDate == "NaN-aN-aN") {
            this.form.BaseInfoManufacturingDate = "";
            this.form.BaseInfoExpirationDate = "";
          }
          this.$parent.params = this.form;
        if(this.form.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.form.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
        }
          this.isSelectPONumber = this.form.BaseInfoCustomerPONumberCheckbox;
          this.isSelectCustomerCode = this.form.BaseInfoCustomerCodeCheckbox;

          //console.log(this.form.GeneralSpecINCIName);
          this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
          this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
          this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
          //this.strSplit(this.form.GeneralSpecNativeHerbs, "nativeHerbsArr");
          // this.form.GeneralSpecNativeHerbs=this.form.GeneralSpecNativeHerbs.replace(/_/g,",");
          this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");
          this.strSplit(this.form.GeneralSpecPercentage, "percentageArr");

          if (this.form.genSpecCustomField) {
            this.genSpecCustomFieldAttr = JSON.parse(
              this.form.genSpecCustomField
            );
          }
          if (this.form.analysisCustomField) {
            this.analysisCustomFieldAttr = JSON.parse(
              this.form.analysisCustomField
            );
          }
          if (this.form.tableInputCustomField) {
            this.tableInputCustomFieldAttr = JSON.parse(
              this.form.tableInputCustomField
            );
          }
          this.$parent.loading = false;
          this.$parent.revDateTime = data.updateTime;
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err
          });
          console.log(err);
          this.$parent.loading = false;
        });
    },
    manufacturingDateChange() {
      this.form.BaseInfoManufacturingDate = this.addDate(
        10,
        new Date(this.form.BaseInfoManufacturingDate),
        "day"
      );

      this.form.BaseInfoExpirationDate = this.addDate(
        this.shelflifeNumber,
        new Date(this.form.BaseInfoManufacturingDate),
        "month"
      );
      this.form.BaseInfoExpirationDate = this.addDate(
        10,
        new Date(this.form.BaseInfoExpirationDate),
        "day"
      );

      this.form.BaseInfoManufacturingDate = this.formatTime(
        this.form.BaseInfoManufacturingDate,
        "yyyy-MM-dd"
      );
      this.form.BaseInfoExpirationDate = this.formatTime(
        this.form.BaseInfoExpirationDate,
        "yyyy-MM-dd"
      );

      console.log(this.form.BaseInfoManufacturingDate);
      console.log(this.form.BaseInfoExpirationDate);
    },
    selectPONumber(val) {
      this.isSelectPONumber = val;
    },
    selectCustomerCode(val) {
      this.isSelectCustomerCode = val;
    },
    printPage() {
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = "";
      // if(this.form.BaseInfoProductName != undefined && this.form.BaseInfoProductName != ""){
      //   printFileName = this.form.BaseInfoProductName.replace(":","~").replace("：", "~");
      // }
      // if(this.form.BaseInfoLot != undefined && this.form.BaseInfoLot != ""){
      //   printFileName = printFileName + this.form.BaseInfoLot;
      // }
      printFileName =
        "(CoA)" +
        this.form.BaseInfoProductName +
        this.form.BaseInfoLot +
        "Rev." +
        this.$parent.params.revNumber;

      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
          documentTitle: printFileName
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    aloneprintPage() {
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      const style = "@page {margin:0;} @media print{}";
      var printFileName = "";
      printFileName =
        "(CoA)" + this.form.BaseInfoProductName + this.form.BaseInfoLot;

      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "alonePrintDiv",
          type: "html",
          style: style,
          scanStyles: false,
          documentTitle: printFileName
        });
      }, 1.5);
    },
    tagHandle(array, tagCotext, index) {
      //tag标签点击事件
      console.info(array, tagCotext, index);
      this.$prompt("修改值-->" + tagCotext, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    }, // ingredients标签操作
    handleClose(tag, arr) {
      this[arr].splice(this[arr].indexOf(tag), 1);
    },
    showInput: function(inputVisible, ref) {
      console.log(inputVisible);
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    handleInputConfirm(value, arr, visible) {
      let inputValue = this[value];
      if (inputValue) {
        console.info(this[arr]);
        this[arr].push(inputValue);
      }

      this[visible] = false;
      this[value] = "";
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach(v => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
    addGenSpecCustomRow(type, index) {
      if (type == 1) {
        this.genSpecCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.genSpecCustomFieldAttr, index);
      }
    },
    addAnalysisCustomRow(type, index) {
      if (type == 1) {
        this.analysisCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.analysisCustomFieldAttr, index);
      }
    },
    addTableInput(type, index) {
      if (type == 1) {
        this.tableInputCustomFieldAttr.push({ ...this.tableInputField });
      } else {
        this.$delete(this.tableInputCustomFieldAttr, index);
      }
    },
    addToLibrary(inventoryType) {
      this.inventoryDialogVisible = false;
      let paramsQuery = { ...this.$route.query };
      if (paramsQuery.id) {
        this.batchAddLibrary(paramsQuery.id, inventoryType);
      } else {
        this.handleNew(inventoryType);
      }
    },
    batchAddLibrary(id, inventoryType) {
      this.$confirm("Are you sure batch add. Continue?", "Tips", {
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        distinguishCancelAndClose: true,
        type: "warning"
      }).then(() => {
        let params = { ids: [id], type: "coa", inventoryType: inventoryType };
        console.log("addToLibrary======params");
        console.log(params);
        this.$api
          .batchAddLibrary(params)
          .then(() => {
            this.$message({
              type: "success",
              message: "batch Add SuccessFully"
            });
            this.form.libraryRevNumber = this.form.revNumber;
            this.form.libraryCreateTime = base.methods.formatTime(
              base.methods.getLocalNowTime(),
              "dd/MM/yyyy hh:mm"
            );
            let jsonStr = JSON.stringify(this.form);
            this.formData.jsonValue = jsonStr;
            this.formData.id = id;
            this.$api.coa
              .editCoaUrl(this.formData)
              .then(() => {
                this.$message({
                  type: "success",
                  message: "SuccessFul"
                });
                this.verifyChange(id);
              })
              .catch(err => {
                this.$message({
                  type: "error",
                  message: err
                });
                console.log(err);
              });
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    fatherMethod(revNumber) {
      console.log("revNumber");
      console.log(revNumber);
      this.$parent.params.revNumber = revNumber;
    },
    getBaseInfoDetail(newVal) {
      console.info('3333333')
      this.shelflifeNumber =
        newVal.shelflifeNumber != undefined
          ? parseInt(newVal.shelflifeNumber)
          : 36;
      console.log("this.shelflifeNumber===" + this.shelflifeNumber);

      if (newVal.newNoteArr == undefined) {
        this.form.newNoteArr = [];
        this.newNoteArr = [];
      } else {
        this.newNoteArr = JSON.parse(newVal.newNoteArr);
      }

      this.form.oldProductType = newVal.productType
      this.form.ProductSubType = newVal.productSubType
      this.form.versionNumber = newVal.versionNumber;
      this.form.revNumber = newVal.revNumber;

      this.form.GeneralSpecCommonName = newVal.commonName;
      this.form.GeneralSpecINCIName = newVal.inciName;
      this.form.GeneralSpecBotanicalName = newVal.botanicalName;
      this.form.GeneralSpecNativeHerbs = newVal.majorConstituentsInNativeHerbs;
      this.form.GeneralSpecPartUsed = newVal.partUsed;
      this.form.GeneralSpecPercentage = newVal.percentage;
      this.form.GeneralSpecCarrier = newVal.carrierSolvent;
      this.form.GeneralSpecPreservative = newVal.preservative;

      this.form.BaseInfoCountryofOrigin = newVal.countryOfOrigin;

      if(typeof(newVal.analysisRangeBioactivesLevel)!="undefined"){
        this.form.SpecificationBioactiveslevel = newVal.analysisRangeBioactivesLevel.replace('≥','');
      }
      
      this.form.SpecificationpH = newVal.analysisRangePh;

      // if ( newVal.analysisRangeBioactivesLevel != "" && newVal.analysisRangeBioactivesLevel != null) {
      //   this.form.SpecificationBioactiveslevel = "≥ " + newVal.analysisRangeBioactivesLevel + " %";
      // }
      //this.form.SpecificationHeavyMetal = newVal.analysisRangeHeavyMetal;
      //this.form.SpecificationPesticides = newVal.analysisTestPesticide;

      this.form.SpecificationArsenic = newVal.analysisArsenic;
      this.form.SpecificationCadmium = newVal.analysisCadmium;
      this.form.SpecificationLead = newVal.analysisLead;
      this.form.SpecificationMercury = newVal.analysisMercury;

      this.form.TestMethodArsenic = newVal.analysisMethodArsenic;
      this.form.TestMethodCadmium = newVal.analysisMethodCadmium;
      this.form.TestMethodLead = newVal.analysisMethodLead;
      this.form.TestMethodMercury = newVal.analysisMethodMercury;

      this.form.GeneralSpecCustomRowKey1 = newVal.generalSpecificationKey1;
      this.form.GeneralSpecCustomRowValue1 = newVal.generalSpecificationValue1;
      this.form.GeneralSpecCustomRowKey2 = newVal.generalSpecificationKey2;
      this.form.GeneralSpecCustomRowValue2 = newVal.generalSpecificationValue2;
      this.form.GeneralSpecCustomRowKey3 = newVal.generalSpecificationKey3;
      this.form.GeneralSpecCustomRowValue3 = newVal.generalSpecificationValue3;

      this.form.SpecificationCustomRowKey1 = newVal.liquidExtCustomerKey1;
      this.form.SpecificationCustomRowValue1 = newVal.liquidExtCustomerValue1;
      this.form.TestMethodCustomRowValue1 = newVal.liquidExtCustomerValue2;

      this.form.SpecificationCustomRowKey2 = newVal.liquidExtCustomerKey3;
      this.form.SpecificationCustomRowValue2 = newVal.liquidExtCustomerValue3;
      this.form.TestMethodCustomRowValue2 = newVal.liquidExtCustomerValue3TM;

      this.form.SpecificationCustomRowKey3 = newVal.liquidExtCustomerKey4;
      this.form.SpecificationCustomRowValue3 = newVal.liquidExtCustomerValue4;
      this.form.TestMethodCustomRowValue3 = newVal.liquidExtCustomerValue4TM;

      this.form.SpecificationCustomRowKey4 = newVal.liquidExtCustomerKey5;
      this.form.SpecificationCustomRowValue4 = newVal.liquidExtCustomerValue5;
      this.form.TestMethodCustomRowValue4 = newVal.liquidExtCustomerValue5TM;

      if (
        newVal.productType == "Liquid EXT-S" ||
        newVal.productType == "Liquid EXT-B" ||
        newVal.productType == "SDNP-L"
      ) {
        this.form.SpecificationTotalPlateCount = newVal.analysisRangeTotalPlateCount;
      } else {
        this.form.SpecificationTotalPlateCount = newVal.specificationTotalPlateCount;
      }

      if (
        newVal.productType == "Liquid EXT-S" ||
        newVal.productType == "Liquid EXT-B" ||
        newVal.productType == "SDNP-L"
      ) {
        this.form.SpecificationYeastandMold = newVal.specificationYeastAndMold;
      } else {
        this.form.SpecificationYeastandMold = newVal.specificationYeastAndMold;
      }

      this.form.SpecificationEColi = newVal.specificationEcoli;
      this.form.SpecificationSalmonella = newVal.specificationSalmonella;
      this.form.SpecificationStaphAureus = newVal.specificationStaphAureus;
      console.log(newVal.specificationTotalColiforms);

      if (
        newVal.productType == "Liquid EXT-S" ||
        newVal.productType == "Liquid EXT-B" ||
        newVal.productType == "SDNP-L"
      ) {
        this.form.SpecificationTotalColiform = newVal.testMethodTotalColiforms;
      } else {
        this.form.SpecificationTotalColiform = newVal.specificationTotalColiforms;
      }

      this.form.genSpecCustomField = newVal.genSpecCustomField;
      this.form.analysisCustomField = newVal.analysisCustomField;
      this.form.tableInputCustomField = newVal.tableInputCustomField;
      this.form.tableInput1 = newVal.tableInput1;
      this.form.tableInput2 = newVal.tableInput2;
      this.form.tableInput3 = newVal.tableInput3;
      this.form.tableInput4 = newVal.tableInput4;
      this.form.tableInput5 = newVal.tableInput5;
      if (this.form.genSpecCustomField) {
        this.genSpecCustomFieldAttr = JSON.parse(this.form.genSpecCustomField);
      }
      if (this.form.analysisCustomField) {
        this.analysisCustomFieldAttr = JSON.parse(this.form.analysisCustomField);
      }
      if (this.form.tableInputCustomField) {
        this.tableInputCustomFieldAttr = JSON.parse(this.form.tableInputCustomField);
      }

      this.form.SpecificationColor = newVal.color;
      this.form.SpecificationOdor = newVal.odor;
      this.form.SpecificationTaste = newVal.taste;
      console.log("select item code");
      this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
      this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
      this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
      //this.strSplit(this.form.GeneralSpecNativeHerbs, "nativeHerbsArr");
      this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");
      this.strSplit(this.form.GeneralSpecPercentage, "percentageArr");
      console.log("======this.form==========");
      console.log(this.form);
      console.log("======Liquid-baseInfoItemCodeDetail-end==========");
    },
    addNewNoteTableClick() {
      this.newNoteArr.push({
        content: this.newNote
      });
      this.newNote = "";
    },
    deleteNewNoteTableClick(index) {
      this.newNoteArr.splice(index, 1);
      // this.$delete(this.newNoteArr,index)
    },
    verifyChange(pId = 0) {
      console.log("pId===" + pId);
      let params = {
        id: pId != 0 ? pId : this.$route.query.id,
        isFlag: true
      };
      this.$api.coa
        .verfiedCoaItem(params)
        .then(data => {
          console.log(data);
          this.$message({ type: "success", message: "Verified SuccessFul" });
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if (userInfo) {
      this.formData.updateName = userInfo.username;
    }
    this.formData.createTime = base.methods.getLocalNowTime();
    console.log("this.formData.createTime");
    console.log(this.formData.createTime);
  },
  mounted() {
    let params = { ...this.$route.query };
    if (params.id) {
      if (params.aloneprint) {
        this.isShowAlonePrint = params.aloneprint == "1" ? true : false;
      }
      if (params.showUpdate) {
        this.isShowUpdate = params.showUpdate == "1" ? true : false;
      }
      this.getDetailById(params);
      this.formData.id = params.id;
    }
  },
  watch: {
    baseInfoItemCodeDetail: {
      handler(newVal) {
        console.log("22======Liquid-baseInfoItemCodeDetail-start==========");
        console.log(newVal);
        this.getBaseInfoDetail(newVal);
      },
      //为了发现内部的内容发生了变化把状态改变成true
      deep: true,
      immediate: true //首次赋值的时候也会触发watch里面的handler事件
    },
    baseinfoCustom: {
      handler(newVal) {
        console.log("======Liquid-baseinfoCustombaseinfoCustombaseinfoCustombaseinfoCustom-start==========");
        this.baseInfoCustomFieldList = newVal
        console.log(newVal);
      },
      //为了发现内部的内容发生了变化把状态改变成true
      deep: true,
      immediate: true //首次赋值的时候也会触发watch里面的handler事件
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>
<style>
@import "../module/CoaStyle.css";
</style>
