import { render, staticRenderFns } from "./OilShxto.vue?vue&type=template&id=0ac3a1bc&scoped=true&"
import script from "./OilShxto.vue?vue&type=script&lang=js&"
export * from "./OilShxto.vue?vue&type=script&lang=js&"
import style0 from "./OilShxto.vue?vue&type=style&index=0&id=0ac3a1bc&prod&lang=scss&scoped=true&"
import style1 from "./OilShxto.vue?vue&type=style&index=1&id=0ac3a1bc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac3a1bc",
  null
  
)

export default component.exports