<template>
  <div
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
    :style="{ width: sWidth }"
  >
    <div class="new-item-head">
      Coa Import {{ titleType }}
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <span
        style="
          position: absolute;
          right: 10px;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
        "
      >
        <el-dropdown placement v-if="userInfo.username">
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ userInfo.username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="logoutHandle"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </span>
    </div>
    <div style="position: absolute;text-align: left;margin: 120px 0 0 120px;color: red;z-index: 9;">
      <div style="height: 30px;line-height: 30px;">Rev：{{params.revNumber}}</div>
      <div style="height: 30px;line-height: 30px;">Date：{{formatTime(revDateTime,"yyyy-MM-dd hh:mm")}}</div>
    </div>
    <div class="new-item-content">
      <!-- base info -->
      <div class="base-info" v-if="!isShowAlonePrint">
        <div style="margin-left: 6%">Base Info</div>
        <div>
          <div>
            <span>Product Type：</span>
            <el-select
              ref="productType"
              :class="{ 'velidate-item': false }"
              @change="productTypeChange"
              v-model="params.productType"
              placeholder="select"
              clearable
              :disabled="isSelectProType"
            >
              <el-option
                v-for="(item, i) in productTypeList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>Item Code：</span>
            <el-autocomplete
              clearable
              ref="itemCode"
              v-model="params.BaseInfoItemCode"
              :fetch-suggestions="codeSearch"
              placeholder="Please Input"
              popper-class="select-option"
              :popper-append-to-body="false"
              @select="itemCodeSearch"
            ></el-autocomplete>
          </div>
          <div>
            <span>Product SubType：</span>
            <el-input
              v-model="params.ProductSubType"
              placeholder="Please Input"
            ></el-input>
          </div>
          <div>
            <span>Product Name：</span>
            <template v-if="params.oldProductType == 'CSFMU-B'">
                <el-input
                  :value="
                    params.BaseInfoItemCode.replace('CSFMU-', '').replace('CSGMU-', '') +
                    ' Extract'
                  "
                  readonly="readonly"
                ></el-input>
            </template>
            <template v-else>
              <el-input  v-model="params.BaseInfoProductName" readonly="readonly"></el-input>
            </template>
          </div>
          <div>
            <span>Version Number：</span>
            <el-input
              v-model="params.versionNumber"
              placeholder="Please Input"
            ></el-input>
          </div>
          <div>
            <span>Lot #：</span>
            <el-input
              v-model="params.BaseInfoLot"
              placeholder="Please Input"
            ></el-input>
          </div>
          <div v-if="params.oldProductType == 'CSFMU-B'">
            <span>Custom Product Name：</span>
            <el-input v-model="params.BaseInfoProductName" placeholder="Please Input" readonly="readonly"></el-input>
          </div>
          <div style="height: auto;margin-top:10px;">
            <div style="display: inline-block; text-align: left">
              <table style="border: 1px solid #eee;">
                <tr v-for="(item, baseInfoInputIndex) in BaseInfoCustomFieldAttr" :key="baseInfoInputIndex + 1">
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="Please Input" style="width: 100%" v-model="item.baseInfoInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="Please Input" v-model="item.baseInfoInput2"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addBaseInfoInput(1, 0)">+</el-button>
                    <el-button v-show="BaseInfoCustomFieldAttr.length>1" type="danger" class="button-new-tag" size="small" @click="addBaseInfoInput(0, baseInfoInputIndex)">-</el-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Liquid v-if="otherType == 'Liquid'" :baseInfoItemCodeDetail="BaseInfoItemCodeDetail" :baseinfoCustom="BaseInfoCustomFieldAttr"></Liquid>
      <OilEhxto v-else-if="otherType == 'EHXTO'" :baseInfoItemCodeDetail="BaseInfoItemCodeDetail" :baseinfoCustom="BaseInfoCustomFieldAttr"></OilEhxto>
      <OilShxto v-else-if="otherType == 'SHXTO'" :baseInfoItemCodeDetail="BaseInfoItemCodeDetail" :baseinfoCustom="BaseInfoCustomFieldAttr"></OilShxto>
      <Powder v-else :baseInfoItemCodeDetail="BaseInfoItemCodeDetail" :baseinfoCustom="BaseInfoCustomFieldAttr"></Powder>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import Liquid from "./CoaModule/Liquid.vue";
import OilEhxto from "./CoaModule/OilEhxto.vue";
import Powder from "./CoaModule/Powder.vue";
import OilShxto from "./CoaModule/OilShxto.vue";
// import FANDVVue from './PrintProductTypeModule/FANDV.vue';
export default {
  name: "coa-item",
  mixins: [base],
  components: { Liquid, OilEhxto, OilShxto, Powder},
  data() {
    return {
      otherType: "Liquid",
      isSelectProType: false,
      titleType: "Add",
      productTypeList: [
        { label: "Powder", value: "Powder" },
        { label: "Liquid", value: "Liquid" },
        { label: "EHXTO", value: "EHXTO" },
        { label: "SHXTO", value: "SHXTO" },
      ],
      params: {
        productType: "",
        oldProductType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        ProductSubType: "",
        BaseInfoLot: "",
        revNumber:1,
        versionNumber:"",
      },
      BaseInfoItemCodeDetail: {},
      sWidth: 0,
      loading: false,
      itemCodeSearchLock: "",
      isShowAlonePrint:false,
      revDateTime:base.methods.getLocalNowTime(),
      BaseInfoCustomFieldAttr: [{
        baseInfoInput1: "",
        baseInfoInput2: ""
      }],
      baseInfoField: {
        baseInfoInput1: "",
        baseInfoInput2: ""
      },
    };
  },
  computed: {},
  methods: {
    back() {
      this.$router.push({ path: "/coa-items" });
    },
    addBaseInfoInput(type,index){
      if(type==1){
        this.BaseInfoCustomFieldAttr.push({ ...this.baseInfoField });
      }
      else{
        this.$delete(this.BaseInfoCustomFieldAttr,index)
      }
    },
    productTypeChange() {
      console.log(this.params.productType);
      this.otherType = this.params.productType;
      this.params.revNumber = 1
    },
    codeSearch(queryString, cb) {
      this.getDetailByItemCodeSearch(queryString);
      // var restaurants = this.$store.state.codeList;
      var codeList = JSON.parse(localStorage.getItem("itemCodeListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    itemCodeSearch(val) {
      if (typeof val.value != "undefined") {
        console.info(val.value);
        this.getDetailByItemCodeSearch(val.value);
      }
    },
    getDetailByItemCodeSearch(searchValue) {
      if (this.itemCodeSearchLock == searchValue) {
        this.itemCodeSearchLock = searchValue;
        return;
      }
      if (typeof searchValue == "undefined" || searchValue.length == 0) {
        return;
      }

      this.$api.handleSpec
        .getDetailByItemCode(searchValue)
        .then((res) => {
          console.log('======getDetailByItemCode======')
          console.log(res)
          console.log(res.productType)
          if (res.jsonValue != undefined) {
            this.params.BaseInfoProductName = res.productName;
            this.params.ProductSubType = res.productSubType;
            this.BaseInfoItemCodeDetail =JSON.parse(res.jsonValue);
            this.params.oldProductType = res.productType
            if(this.BaseInfoItemCodeDetail.baseInfoCustomField){
              this.BaseInfoCustomFieldAttr = JSON.parse(this.BaseInfoItemCodeDetail.baseInfoCustomField)
            }
            //#region 
            if (
              res.productType == "CSFMU-B" || res.productType == "CSFMU-S" || res.productType == "REXT" || 
              res.productType == "F&V" || res.productType == "FMU-D" || res.productType == "HEXT" || 
              res.productType == "OEXT" || res.productType == "PCG" || res.productType == "PCG-CSFMU-S" || 
              res.productType == "SDNP" || res.productType == "PCG-CSFMU-B"
            ) {
              this.params.productType="Powder"
              this.otherType="Powder"
            }
            else if(res.productType == "Liquid-S" || res.productType == "Liquid-B" || res.productType == "SDNP-L" || 
            res.productType == "Liquid EXT-B" || res.productType == "Liquid EXT-S"){
              this.params.productType="Liquid"
              this.otherType="Liquid"
            }
            else if(res.productType == "SHXTO"){
              this.params.productType="SHXTO"
              this.otherType="SHXTO"
            }
            else if(res.productType == "EHXTO"){
              this.params.productType="EHXTO"
              this.otherType="EHXTO"
            }
            //#endregion
            this.params.versionNumber = this.BaseInfoItemCodeDetail.versionNumber;
            console.log("revNumber======"+this.BaseInfoItemCodeDetail.revNumber)
            if(this.BaseInfoItemCodeDetail.revNumber){
              this.params.revNumber = this.BaseInfoItemCodeDetail.revNumber==0?1:this.BaseInfoItemCodeDetail.revNumber;
            }
            else{
              this.BaseInfoItemCodeDetail.revNumber = 1
              this.params.revNumber = 1
            }
            console.log("this.BaseInfoItemCodeDetail");
            console.log(this.BaseInfoItemCodeDetail);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    formatTime(time,format){
      if(time){
        return base.methods.formatTime(time,format);
      }
      else{
        return base.methods.formatTime(new Date(),format);
      }
    },
     
  },
  mounted() {
    this.titleType = "Add";
    let params = { ...this.$route.query };
    if (params.id) {
      if(params.aloneprint){
        this.isShowAlonePrint = params.aloneprint == "1" ? true : false
      }
      this.titleType = "Edit";
      this.otherType=params.productType;
      if(this.otherType=="OilEhxto"){
        this.otherType="EHXTO";
      }else if(this.otherType=="OilShxto"){
        this.otherType="SHXTO";
      }
      this.isSelectProType = true;
      //this.getData(params.id);
      this.newItemType = "Edit";
    } else {
      this.newItemType = "New";
    }
    this.sWidth = "1920px";
  },
  created() {},
 
};
</script>
<style lang="scss" scoped>
@import "./module/NewItemStyle.scss";
</style>

