<template>
  <div class="page" style="text-align: left; padding:0 40px 0 40px">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox==false">Draco Natural Products, Inc.</b>
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox">{{printModel.form.BaseInfoEditPrintTitle}}</b>
            </div>
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>CERTIFICATE OF ANALYSIS</u></b>
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
        <div>
          <div style="width: 100%">
            <table style="width: 100%">
              <tr v-show="printModel.form.productType">
                <td style="width: 25%"><b>Product Type</b></td>
                <td>
                  {{ printModel.form.productType }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoProductName">
                <td style="width: 25%"><b>Product Name</b></td>
                <td>
                  {{ printModel.form.BaseInfoProductName }}
                </td>
              </tr>
              <tr v-show="printModel.form.ProductSubType">
                <td style="width: 25%"><b>Product SubType</b></td>
                <td>
                  <span t-field="o.name" />{{ printModel.form.ProductSubType }}
                </td>
              </tr>
              <tr v-show="printModel.form.versionNumber">
                <td style="width: 25%"><b>Version Number</b></td>
                <td>
                  <span t-field="o.name" />{{ printModel.form.versionNumber }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoLot">
                <td style="width: 25%"><b>Lot #</b></td>
                <td>
                  {{ printModel.form.BaseInfoLot }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoItemCode">
                <td style="width: 25%"><b>Item Code</b></td>
                <td>
                  {{ printModel.form.BaseInfoItemCode }}
                </td>
              </tr>
              
              
              <!-- <tr v-show="printModel.form.BaseInfoCustomProductName">
                <td style="width: 25%"><b>Custom Product Name</b></td>
                <td>
                  {{ printModel.form.BaseInfoCustomProductName }}
                </td>
              </tr> -->

              <tr v-show="printModel.form.BaseInfoCountryofOrigin">
                <td style="width: 25%"><b>Country of Origin</b></td>
                <td>
                  {{ printModel.form.BaseInfoCountryofOrigin }}
                </td>
              </tr>

              <tr v-show="printModel.form.BaseInfoCustomerPONumber">
                <td style="width: 25%"><b>Customer PO Number</b></td>
                <td> 
                  <!-- {{ printModel.form.BaseInfoCustomerPONumber }} -->
                  {{ printModel.form.BaseInfoCustomerPONumberText }}
                </td>
              </tr>

              <tr v-show="printModel.form.BaseInfoCustomerCodeCheckbox">
                <td style="width: 25%"><b>Customer Code</b></td>
                <td>
                  <!-- <el-checkbox
                    v-model="printModel.form.BaseInfoCustomerCodeCheckbox"
                    placeholder="Please Input"
                  ></el-checkbox> -->
                  <!-- {{ printModel.form.BaseInfoCustomerCodeCheckbox }} -->
                  {{ printModel.form.BaseInfoCustomerCode }}
                </td>
              </tr>

              <tr v-show="printModel.form.BaseInfoCOfOFAndCOfOE">
                <td style="width: 25%">
                  <b  >Country of Origin Feedstock and Country of Origin Extraction</b >
                </td>
                <td>
                  <!-- <el-checkbox
                    v-model="printModel.form.BaseInfoCOfOFAndCOfOE"
                    placeholder="Please Input"
                  ></el-checkbox> -->
                  <!-- {{ printModel.form.BaseInfoCOfOFAndCOfOE }} -->
                  {{ printModel.form.BaseInfoCOfOFAndCOfOEText }}
                </td>
              </tr>
              
              <tr v-show="printModel.form.BaseInfoSAPNumber">
                <td style="width: 25%"><b>SAP Number</b></td>
                <td>
                  <!-- <el-checkbox
                    v-model="printModel.form.BaseInfoSAPNumber"
                    placeholder="Please Input"
                  ></el-checkbox> -->
                  <!-- {{ printModel.form.BaseInfoSAPNumber }} -->
                  {{ printModel.form.BaseInfoSAPNumberText }}
                </td>
              </tr>
              
              <tr v-show="printModel.form.BaseInfoManufacturingDate">
                <td style="width: 25%"><b>Manufacturing Date</b></td>
                <td>
                  {{ formatTime(printModel.form.BaseInfoManufacturingDate,"yyyy-MM") }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoExpirationDate">
                <td style="width: 25%"><b>Expiration Date</b></td>
                <td>
                  {{ formatTime(printModel.form.BaseInfoExpirationDate,"yyyy-MM") }}
                </td>
              </tr>

              <tr v-show="false">
                <td style="width: 25%"><b>MATERIAL USES</b></td>
                <td>
                  Botanical powdered extract used as food/dietary and
                  cosmetic/personal care ingredient
                </td>
              </tr>
              <tr v-show="false">
                <td><b>EMERGENCY CONTACT</b></td>
                <td>Call 911 in case of emergency</td>
              </tr>
            </table>
            <table style="width: 99%" v-show="printModel.baseInfoCustomFieldList && printModel.baseInfoCustomFieldList.length>0">
              <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.baseInfoCustomFieldList" :key="baseinfoIndex">
                <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
                <td style="width: 75%">
                  {{ baseinfoCustom.baseInfoInput2 }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="clear: both"></div>
        <!-- <div style="height: 20px"></div> -->
        <div style="font-size: 18px">
          <b><u>General Specification</u></b>
        </div>

        <!-- 遍历table -->
        <template v-if="isSingle().count > 1">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0">Common Name</th>
                <th v-show="printModel.inclNameArr.length > 0">INCI Name</th>
                <th v-show="printModel.botanicalNameArr.length > 0">Botanical Name</th>
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="printModel.commonNameArr.length > 0">
                  <div v-show="i <= printModel.commonNameArr.length">{{
                    printModel.commonNameArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.inclNameArr.length > 0">
                  <div v-show="i <= printModel.inclNameArr.length">{{
                    printModel.inclNameArr[i]
                  }}</div>
                </td>
                <td v-show="printModel.botanicalNameArr.length > 0">
                  <div
                    v-show="i <= printModel.botanicalNameArr.length"
                    >{{ printModel.botanicalNameArr[i] }}</div
                  >
                </td>
                <td v-show="printModel.partUsedArr.length > 0">
                  <div v-show="i <= printModel.partUsedArr.length">{{
                    printModel.partUsedArr[i]
                  }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <div style="width: 100%" v-else-if="isSingle().count==1">
          <table style="width: 100%" cellspacing="0" class="tab">
            <tr>
              <td style="width: 25%">
                <b>{{isSingle().name}}</b>
              </td>
              <td style="width: 75%">
                <span  v-for="(item,val,index) in isSingle().arr" :key="index" >{{item}}<span v-if="val+1<isSingle().arr.length">, </span></span>
              </td>
            </tr>
          </table>
        </div>
        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.form.tableInput1" style="width:20%;">{{ printModel.form.tableInput1 }}</td>
                <td v-show="printModel.form.tableInput2" style="width:20%;">{{ printModel.form.tableInput2 }}</td>
                <td v-show="printModel.form.tableInput3" style="width:20%;">{{ printModel.form.tableInput3 }}</td>
                <td v-show="printModel.form.tableInput4" style="width:20%;">{{ printModel.form.tableInput4 }}</td>
                <td v-show="printModel.form.tableInput5" style="width:20%;">{{ printModel.form.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <div>
          <div style="width: 100%">
            <table style="width: 100%">
              <tbody>
              <tr v-show="printModel.form.GeneralSpecAppearance">
                <td style="width: 25%"><b>Appearance</b></td>
                <td>
                  {{ printModel.form.GeneralSpecAppearance }}
                </td>
              </tr>

              <tr v-show="printModel.form.GeneralSpecCustomRowKey1">
                <td style="width: 25%"><b>{{ printModel.form.GeneralSpecCustomRowKey1 }}</b></td>
                <td style="width: 75%">
                  {{
                    printModel.form.GeneralSpecCustomRowValue1
                  }}
                </td>
              </tr>
              <tr v-show="printModel.form.GeneralSpecCustomRowKey2">
                <td style="width: 25%"><b>{{ printModel.form.GeneralSpecCustomRowKey2 }}</b></td>
                <td style="width: 75%">
                  {{
                    printModel.form.GeneralSpecCustomRowValue2
                  }}
                </td>
              </tr>

              <tr v-show="printModel.form.GeneralSpecCustomRowKey3">
                <td style="width: 25%"><b>{{ printModel.form.GeneralSpecCustomRowKey3 }}</b></td>
                <td style="width: 75%">
                  {{
                    printModel.form.GeneralSpecCustomRowValue3
                  }}
                </td>
              </tr>
              </tbody>
              
              <tbody
                v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr"
                :key="genIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 30%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 70%">
                    <span t-field="o.apperance">{{ item.customValue }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div style="height: 20px"></div> -->
        <div style="font-size: 18px">
          <b><u>Analysis</u></b>
        </div>
        <!-- <div style="height: 10px"></div> -->
        <div>
          <div style="width: 100%">
            <table style="width: 98%" cellspacing="0" class="tab">
              <tbody>
              <tr>
                <td style="width: 25%">
                  <b>Test Item</b>
                </td>
                <td style="width: 25%">
                  <b>Specification</b>
                </td>
                <td style="width: 25%">
                  <b>Result</b>
                </td>
                <td style="width: 25%">
                  <b>Test Method</b>
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationAcidValue ||
                  printModel.form.ResultAcidValue  
                "
              >
                <td class="FontWeight"><b>Acid Value</b></td>
                <td>
                  NMT{{ printModel.form.SpecificationAcidValue }}mg KOH/G
                </td>
                <td>
                  {{ printModel.form.ResultAcidValue }}
                </td>
                <td>
                  {{ printModel.form.TestMethodAcidValue }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationPeroxideValue ||
                  printModel.form.ResultPeroxideValue  
                "
              >
                <td class="FontWeight"><b>Peroxide Value</b></td>
                <td>
                  NMT{{ printModel.form.SpecificationPeroxideValue }}meq/kg
                </td>
                <td>
                  {{ printModel.form.ResultPeroxideValue }}
                </td>
                <td>
                  {{ printModel.form.TestMethodPeroxideValue }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationSaponificationValue ||
                  printModel.form.ResultSaponificationValue  
                "
              >
                <td class="FontWeight"><b>Saponification Value</b></td>
                <td>
                  {{ printModel.form.SpecificationSaponificationValue }}mg KOH/G
                </td>
                <td>
                  {{ printModel.form.ResultSaponificationValue }}
                </td>
                <td>
                  {{ printModel.form.TestMethodSaponificationValue }}
                </td>
              </tr>

              <tr
                v-show="
                  printModel.form.SpecificationUnsaponifiableMatter ||
                  printModel.form.ResultUnsaponifiableMatter  
                "
              >
                <td class="FontWeight"><b>Unsaponifiable Matter</b></td>
                <td>
                  {{ printModel.form.SpecificationUnsaponifiableMatter }}%
                </td>
                <td>
                  {{ printModel.form.ResultUnsaponifiableMatter }}
                </td>
                <td>
                  {{ printModel.form.TestMethodUnsaponifiableMatter }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationMoistureAndVolatiles ||
                  printModel.form.ResultMoistureAndVolatiles  
                "
              >
                <td class="FontWeight"><b>Moisture & Volatiles</b></td>
                <td>
                  NMT{{ printModel.form.SpecificationMoistureAndVolatiles }}%
                </td>
                <td>
                  {{ printModel.form.ResultMoistureAndVolatiles }}
                </td>
                <td>
                  {{ printModel.form.TestMethodMoistureAndVolatiles }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationTotalHeavyMetals ||
                  printModel.form.ResultTotalHeavyMetals  
                "
              >
                <td class="FontWeight"><b>Total Heavy Metals</b></td>
                <td>
                  &lt;{{ printModel.form.SpecificationTotalHeavyMetals }}ppm
                </td>
                <td>
                  {{ printModel.form.ResultTotalHeavyMetals }} {{checkNumber(printModel.form.ResultTotalHeavyMetals)?'ppm':''}}
                </td>
                <td>
                  {{ printModel.form.TestMethodTotalHeavyMetals }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationArsenic ||
                  printModel.form.ResultArsenic  
                "
              >
                <td class="FontWeight"><b>Arsenic</b></td>
                <td>
                  {{ printModel.form.SpecificationArsenic }} ppm
                </td>
                <td>
                  {{ printModel.form.ResultArsenic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodArsenic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationCadmium ||
                  printModel.form.ResultCadmium  
                "
              >
                <td class="FontWeight"><b>Cadmium</b></td>
                <td>
                  {{ printModel.form.SpecificationCadmium }} ppm
                </td>
                <td>
                  {{ printModel.form.ResultCadmium }}
                </td>
                <td>
                  {{ printModel.form.TestMethodCadmium }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationLead ||
                  printModel.form.ResultLead 
                "
              >
                <td class="FontWeight"><b>Lead</b></td>
                <td>
                  {{ printModel.form.SpecificationLead }} ppm
                </td>
                <td>
                  {{ printModel.form.ResultLead }}
                </td>
                <td>
                  {{ printModel.form.TestMethodLead }}
                </td>
              </tr>

              <tr
                v-show="
                  printModel.form.SpecificationMercury ||
                  printModel.form.ResultMercury  
                "
              >
                <td class="FontWeight"><b>Mercury</b></td>
                <td>
                  {{ printModel.form.SpecificationMercury }} ppm
                </td>
                <td>
                  {{ printModel.form.ResultMercury }}
                </td>
                <td>
                  {{ printModel.form.TestMethodMercury }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationPesticides ||
                  printModel.form.ResultPesticides  
                "
              >
                <td class="FontWeight"><b>Pesticides</b></td>
                <td>
                  {{ printModel.form.SpecificationPesticides }}
                </td>
                <td>
                  {{ printModel.form.ResultPesticides }}
                </td>
                <td>
                  {{ printModel.form.TestMethodPesticides }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationColor ||
                  printModel.form.ResultColor  
                "
              >
                <td class="FontWeight"><b>Color</b></td>
                <td>
                  {{ printModel.form.SpecificationColor }}
                </td>
                <td>
                  {{ printModel.form.ResultColor }}
                </td>
                <td>
                  {{ printModel.form.TestMethodColor }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationOdor ||
                  printModel.form.ResultOdor  
                "
              >
                <td class="FontWeight"><b>Odor</b></td>
                <td>
                  {{ printModel.form.SpecificationOdor }}
                </td>
                <td>
                  {{ printModel.form.ResultOdor }}
                </td>
                <td>
                  {{ printModel.form.TestMethodOdor }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationTaste ||
                  printModel.form.ResultTaste  
                "
              >
                <td class="FontWeight"><b>Taste</b></td>
                <td>
                  {{ printModel.form.SpecificationTaste }}
                </td>
                <td>
                  {{ printModel.form.ResultTaste }}
                </td>
                <td>
                  {{ printModel.form.TestMethodTaste }}
                </td>
              </tr>

              <tr
                v-show="
                  printModel.form.SpecificationCustomRowKey1 ||
                  printModel.form.ResultCustomRowKey1 ||
                  printModel.form.TestMethodCustomRowKey1
                "
              >
                <td style="width: 25%"><b>{{ printModel.form.SpecificationCustomRowKey1 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.SpecificationCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.ResultCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.TestMethodCustomRowValue1
                  }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationCustomRowKey2 ||
                  printModel.form.ResultCustomRowKey2 ||
                  printModel.form.TestMethodCustomRowKey2
                "
              >
                <td style="width: 25%"><b>{{ printModel.form.SpecificationCustomRowKey2 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.SpecificationCustomRowValue2
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.ResultCustomRowValue2
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.TestMethodCustomRowValue2
                  }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationCustomRowKey3 ||
                  printModel.form.ResultCustomRowKey3 ||
                  printModel.form.TestMethodCustomRowKey3
                "
              >
                <td style="width: 25%"><b>{{ printModel.form.SpecificationCustomRowKey3 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.SpecificationCustomRowValue3
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.ResultCustomRowValue3
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.TestMethodCustomRowValue3
                  }}
                </td>
              </tr>
              </tbody>
              
              <tbody
                v-for="(
                  item, analysisIndex
                ) in printModel.analysisCustomFieldAttr"
                :key="analysisIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 25%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue1 }}
                  </td>
                </tr>
              </tbody>
              <tbody>
              <!-- Microbiological Tests -->
              <tr
                v-show="
                  printModel.form.SpecificationTotalPlateCount ||
                  printModel.form.ResultTotalPlateCount  
                "
              >
                <td class="FontWeight"><b>Total Plate Count</b></td>
                <td>
                   <span v-show="printModel.form.SpecificationTotalPlateCount">&lt;{{ printModel.form.SpecificationTotalPlateCount }} cfu/g</span>
                </td>
                <td>
                  {{ printModel.form.ResultTotalPlateCount }} {{checkNumber(printModel.form.ResultTotalPlateCount)?'cfu/g':''}}
                </td>
                <td>
                  {{ printModel.form.TestMethodTotalPlateCount }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationYeastAndMold ||
                  printModel.form.ResultYeastAndMold  
                "
              >
                <td class="FontWeight"><b>Yeast and Mold</b></td>
                <td>
                   <span v-show="printModel.form.SpecificationYeastAndMold">&lt;{{ printModel.form.SpecificationYeastAndMold }} cfu/g</span>
                </td>
                <td>
                  {{ printModel.form.ResultYeastAndMold }} {{checkNumber(printModel.form.ResultYeastAndMold)?'cfu/g':''}}
                </td>
                <td>
                  {{ printModel.form.TestMethodYeastAndMold }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationEColi  
                "
              >
                <td class="FontWeight"><b>E. Coli</b></td>
                <td>
                  {{ printModel.form.SpecificationEColi }}
                </td>
                <td>
                  {{ printModel.form.ResultEColi }}
                </td>
                <td>
                  {{ printModel.form.TestMethodEColi }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationSalmonella  
                "
              >
                <td class="FontWeight"><b>Salmonella</b></td>
                <td>
                  {{ printModel.form.SpecificationSalmonella }}
                </td>
                <td>
                  {{ printModel.form.ResultSalmonella }}
                </td>
                <td>
                  {{ printModel.form.TestMethodSalmonella }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationStaphAureus  
                "
              >
                <td class="FontWeight"><b>Staph Aureus</b></td>
                <td>
                  {{ printModel.form.SpecificationStaphAureus }}
                </td>
                <td>
                  {{ printModel.form.ResultStaphAureus }}
                </td>
                <td>
                  {{ printModel.form.TestMethodStaphAureus }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationTotalColiforms ||
                  printModel.form.ResultTotalColiforms  
                "
              >
                <td class="FontWeight"><b>Total Coliforms</b></td>
                <td v-if="!printModel.form.SpecificationTotalColiforms || printModel.form.SpecificationTotalColiforms.toLowerCase()=='negative'">
                    {{ printModel.form.SpecificationTotalColiforms }}
                </td>
                <td v-else>
                  &lt;{{ printModel.form.SpecificationTotalColiforms }} MPN/g
                </td>
                <td>
                  {{ printModel.form.ResultTotalColiforms }}
                </td>
                <td>
                  {{ printModel.form.TestMethodTotalColiforms }}
                </td>
              </tr>

              <tr v-show="printModel.form.MicTestsCustomRowKey1">
                <td style="width: 25%"><b>{{ printModel.form.MicTestsCustomRowKey1 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsSpecificationCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsResultCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsTestMethodCustomRowValue1
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div style="height: 20px"></div> -->

        <div style="font-size: 18px">
          <b><u>Fatty Acid Composition</u></b>
        </div>
        <!-- <div style="height: 10px"></div> -->
        <div>
          <div style="width: 100%">
            <table style="width: 98%" cellspacing="0" class="tab">
              <tbody>
              <tr>
                <td style="width: 25%">
                  <b>Test Item</b>
                </td>
                <td style="width: 25%">
                  <b>Specification</b>
                </td>
                <td style="width: 25%">
                  <b>Result</b>
                </td>
                <td style="width: 25%">
                  <b>Test Method</b>
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC80Caprylic ||
                  printModel.form.ResultC80Caprylic  
                "
              >
                <td class="FontWeight"><b>C8:0 Caprylic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC80Caprylic }}%
                </td>
                <td>
                  {{ printModel.form.ResultC80Caprylic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC80Caprylic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC100Capric ||
                  printModel.form.ResultC100Capric  
                "
              >
                <td class="FontWeight"><b>C10:0 Capric acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC100Capric }}%
                </td>
                <td>
                  {{ printModel.form.ResultC100Capric }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC100Capric }}
                </td>
              </tr>

              <tr v-show="printModel.form.SpecificationLauric || printModel.form.ResultLauric">
                <td class="FontWeight"><b>C12:0 Lauric acid</b></td>
                <td>{{ printModel.form.SpecificationLauric }}%</td>
                <td>{{ printModel.form.ResultLauric }}</td>
                <td>{{ printModel.form.TestMethodLauric }}</td>
              </tr>
              <tr v-show="printModel.form.SpecificationMyristic || printModel.form.ResultMyristic">
                <td class="FontWeight"><b>C14:0 Myristic acid</b></td>
                <td>{{ printModel.form.SpecificationMyristic }}%</td>
                <td>{{ printModel.form.ResultMyristic }}</td>
                <td>{{ printModel.form.TestMethodMyristic }}</td>
              </tr>
              <tr v-show="printModel.form.SpecificationMyristoleic || printModel.form.ResultMyristoleic">
                <td class="FontWeight"><b>C14:1 Myristoleic acid</b></td>
                <td>{{ printModel.form.SpecificationMyristoleic }}%</td>
                <td>{{ printModel.form.ResultMyristoleic }}</td>
                <td>{{ printModel.form.TestMethodMyristoleic }}</td>
              </tr>

              <tr
                v-show="
                  printModel.form.SpecificationC160Palmitic ||
                  printModel.form.ResultC160Palmitic  
                "
              >
                <td class="FontWeight"><b>C16:0 Palmitic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC160Palmitic }}%
                </td>
                <td>
                  {{ printModel.form.ResultC160Palmitic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC160Palmitic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC161Palmitoleic ||
                  printModel.form.ResultC161Palmitoleic
                "
              >
                <td class="FontWeight"><b>C16:1 Palmitoleic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC161Palmitoleic }}%
                </td>
                <td>
                  {{ printModel.form.ResultC161Palmitoleic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC161Palmitoleic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC180Stearic ||
                  printModel.form.ResultC180Stearic  
                "
              >
                <td class="FontWeight"><b>C18:0 Stearic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC180Stearic }}%
                </td>
                <td>
                  {{ printModel.form.ResultC180Stearic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC180Stearic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC181Oleic ||
                  printModel.form.ResultC181Oleic  
                "
              >
                <td class="FontWeight"><b>C18:1 Oleic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC181Oleic }}%
                </td>
                <td>
                  {{ printModel.form.ResultC181Oleic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC181Oleic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC182Linoleic ||
                  printModel.form.ResultC182Linoleic 
                "
              >
                <td class="FontWeight"><b>C18:2 Linoleic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC182Linoleic }}%
                </td>
                <td>
                  {{ printModel.form.ResultC182Linoleic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC182Linoleic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC183αLinolenic ||
                  printModel.form.ResultC183αLinolenic  
                "
              >
                <td class="FontWeight"><b>C18:3 α-Linolenic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC183αLinolenic }}%
                </td>
                <td>
                  {{ printModel.form.ResultC183αLinolenic }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC183αLinolenic }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC183γLinoleicAcid ||
                  printModel.form.ResultC183γLinoleicAcid  
                "
              >
                <td class="FontWeight"><b>C18:3 γ-Linoleic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC183γLinoleicAcid }}%
                </td>
                <td>
                  {{ printModel.form.ResultC183γLinoleicAcid }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC183γLinoleicAcid }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationC183PunicicAcid ||
                  printModel.form.ResultC183PunicicAcid  
                "
              >
                <td class="FontWeight"><b>C18:3 Punicic acid</b></td>
                <td>
                  {{ printModel.form.SpecificationC183PunicicAcid }}%
                </td>
                <td>
                  {{ printModel.form.ResultC183PunicicAcid }}
                </td>
                <td>
                  {{ printModel.form.TestMethodC183PunicicAcid }}
                </td>
              </tr>
              <tr v-show="printModel.form.SpecificationArachidic || printModel.form.ResultArachidic">
                <td class="FontWeight"><b>C 20:0 Arachidic acid</b></td>
                <td>{{ printModel.form.SpecificationArachidic }}%</td>
                <td>{{ printModel.form.ResultArachidic }}</td>
                <td>{{ printModel.form.TestMethodArachidic }}</td>
              </tr>
              <tr v-show="printModel.form.SpecificationArachidonic || printModel.form.ResultArachidonic">
                <td class="FontWeight"><b>C20:4 Arachidonic acid</b></td>
                <td>{{ printModel.form.SpecificationArachidonic }}%</td>
                <td>{{ printModel.form.ResultArachidonic }}</td>
                <td>{{ printModel.form.TestMethodArachidonic }}</td>
              </tr>
              <tr v-show="printModel.form.SpecificationEicosapentaenoic || printModel.form.ResultEicosapentaenoic">
                <td class="FontWeight"><b>C20:5 Eicosapentaenoic acid</b></td>
                <td>{{ printModel.form.SpecificationEicosapentaenoic }}%</td>
                <td>{{ printModel.form.ResultEicosapentaenoic }}</td>
                <td>{{ printModel.form.TestMethodEicosapentaenoic }}</td>
              </tr>
              <tr v-show="printModel.form.SpecificationDocosahexaenoic || printModel.form.ResultDocosahexaenoic">
                <td class="FontWeight"><b>C 22:6 Docosahexaenoic acid</b></td>
                <td>{{ printModel.form.SpecificationDocosahexaenoic }}%</td>
                <td>{{ printModel.form.ResultDocosahexaenoic }}</td>
                <td>{{ printModel.form.TestMethodDocosahexaenoic }}</td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationOthers ||
                  printModel.form.ResultOthers  
                "
              >
                <td class="FontWeight"><b>Others</b></td>
                <td>
                  {{ printModel.form.SpecificationOthers }}
                </td>
                <td>
                  {{ printModel.form.ResultOthers }}
                </td>
                <td>
                  {{ printModel.form.TestMethodOthers }}
                </td>
              </tr>

              <tr
                v-show="
                  printModel.form.MTSpecificationCustomRowKey1 ||
                  printModel.form.MTResultCustomRowKey1 ||
                  printModel.form.MTTestMethodCustomRowKey1
                "
              >
                <td style="width: 25%"><b>{{ printModel.form.MTSpecificationCustomRowKey1 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTSpecificationCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTResultCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTTestMethodCustomRowValue1
                  }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.MTSpecificationCustomRowKey2 ||
                  printModel.form.MTResultCustomRowKey2 ||
                  printModel.form.MTTestMethodCustomRowKey2
                "
              >
                <td style="width: 25%"><b>{{ printModel.form.MTSpecificationCustomRowKey2 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTSpecificationCustomRowValue2
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTResultCustomRowValue2
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTTestMethodCustomRowValue2
                  }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.MTSpecificationCustomRowKey3 ||
                  printModel.form.MTResultCustomRowKey3 ||
                  printModel.form.MTTestMethodCustomRowKey3
                "
              >
                <td style="width: 25%"><b>{{ printModel.form.MTSpecificationCustomRowKey3 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTSpecificationCustomRowValue3
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTResultCustomRowValue3
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MTTestMethodCustomRowValue3
                  }}
                </td>
              </tr>
              </tbody>
              
              <tbody
                v-for="(
                  item, fattyIndex
                ) in printModel.fattyCustomFieldAttr"
                :key="fattyIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 25%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue1 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <!-- <div style="font-size:18px;"><b><u>Microbiological Tests</u></b></div>
        <div style="height: 10px"></div>
        <div>
          <div style="width: 100%">
            <table
              style="width: 98%;"
              cellspacing="0"
              class="tab"
            >
              <tr>
                <td style="width: 25%">
                  <b>Test Item</b>
                </td>
                <td style="width: 25%">
                  <b>Specification</b>
                </td>
                <td style="width: 25%">
                  <b>Result</b>
                </td>
                <td style="width: 25%">
                  <b>Test Method</b>
                </td>
              </tr>

              <tr
                v-show="
                  printModel.form.SpecificationTotalPlateCount ||
                  printModel.form.ResultTotalPlateCount  
                "
              >
                <td class="FontWeight"><b>Total Plate Count</b></td>
                <td>
                   <span v-show="printModel.form.SpecificationTotalPlateCount">&lt;{{ printModel.form.SpecificationTotalPlateCount }} cfu/g</span>
                </td>
                <td>
                  {{ printModel.form.ResultTotalPlateCount }}
                </td>
                <td>
                  {{ printModel.form.TestMethodTotalPlateCount }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationYeastAndMold ||
                  printModel.form.ResultYeastAndMold  
                "
              >
                <td class="FontWeight"><b>Yeast and Mold</b></td>
                <td>
                   <span v-show="printModel.form.SpecificationYeastAndMold">&lt;{{ printModel.form.SpecificationYeastAndMold }} cfu/g</span>
                </td>
                <td>
                  {{ printModel.form.ResultYeastAndMold }}
                </td>
                <td>
                  {{ printModel.form.TestMethodYeastAndMold }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationEColi  
                "
              >
                <td class="FontWeight"><b>E. Coli</b></td>
                <td>
                  {{ printModel.form.SpecificationEColi }}
                </td>
                <td>
                  {{ printModel.form.ResultEColi }}
                </td>
                <td>
                  {{ printModel.form.TestMethodEColi }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationSalmonella  
                "
              >
                <td class="FontWeight"><b>Salmonella</b></td>
                <td>
                  {{ printModel.form.SpecificationSalmonella }}
                </td>
                <td>
                  {{ printModel.form.ResultSalmonella }}
                </td>
                <td>
                  {{ printModel.form.TestMethodSalmonella }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationStaphAureus  
                "
              >
                <td class="FontWeight"><b>Staph Aureus</b></td>
                <td>
                  {{ printModel.form.SpecificationStaphAureus }}
                </td>
                <td>
                  {{ printModel.form.ResultStaphAureus }}
                </td>
                <td>
                  {{ printModel.form.TestMethodStaphAureus }}
                </td>
              </tr>
              <tr
                v-show="
                  printModel.form.SpecificationTotalColiforms ||
                  printModel.form.ResultTotalColiforms  
                "
              >
                <td class="FontWeight"><b>Total Coliforms</b></td>
                <td>
                   <span v-show="printModel.form.SpecificationTotalColiforms">&lt;{{ printModel.form.SpecificationTotalColiforms }} MPN/g</span>
                </td>
                <td>
                  {{ printModel.form.ResultTotalColiforms }}
                </td>
                <td>
                  {{ printModel.form.TestMethodTotalColiforms }}
                </td>
              </tr>

              <tr v-show="printModel.form.MicTestsCustomRowKey1">
                <td style="width: 25%"><b>{{ printModel.form.MicTestsCustomRowKey1 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsSpecificationCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsResultCustomRowValue1
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsTestMethodCustomRowValue1
                  }}
                </td>
              </tr>
              <tr v-show="printModel.form.MicTestsCustomRowKey2">
                <td style="width: 25%"><b>{{ printModel.form.MicTestsCustomRowKey2 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsSpecificationCustomRowValue2
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsResultCustomRowValue2
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsTestMethodCustomRowValue2
                  }}
                </td>
              </tr>
              <tr v-show="printModel.form.MicTestsCustomRowKey3">
                <td style="width: 25%"><b>{{ printModel.form.MicTestsCustomRowKey3 }}</b></td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsSpecificationCustomRowValue3
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsResultCustomRowValue3
                  }}
                </td>
                <td style="width: 25%">
                  {{
                    printModel.form.MicTestsTestMethodCustomRowValue3
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div> -->

        <div>
          <!-- <div style="height: 20px"></div> -->
          <div style="font-size: 18px">
            <b><u>Note</u></b>
          </div>

          <div
            class="footer"
            id="footer"
            style="
              font-size: 11px;
              font-family: Times New Roman;
              margin-top: 10px;
            "
          >
          <div v-show="printModel.form.note1" style="margin-bottom: 10px;">
            <span><i>* Manufacturing Site: Shanghai Tianyuan Plant Products Company, Ltd.  No.18 Hexiang Rd, Baihe Town, Qingpu District, Shanghai.</i></span>
          </div>
          <!-- <div style="height: 10px" v-show="printModel.form.note1"></div> -->
          <div v-show="printModel.form.note2" style="margin-bottom: 10px;">
            <span><i>* The above listed product is non-irradiated product.</i></span>
          </div>
          <!-- <div style="height: 10px" v-show="printModel.form.note1"></div> -->
          <div v-show="printModel.form.note3" style="margin-bottom: 10px;">
            <span><i>* Disclaimer: This product is intended only for animal use and is not intended for human consumption.</i></span>
          </div>
          <!-- <div style="height: 10px" v-show="printModel.form.note1"></div> -->
          <div style="margin-bottom: 10px;">
            <span>
              <i>
                * Color variation from batch to batch of the product may occur due to natural variations of raw material.
              </i>
            </span>
          </div>
          <!-- <div style="height: 10px"></div> -->
          <div style="margin-bottom: 10px;">
            <span>
              <i>
                * Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case.
              </i>
            </span>
          </div>
          <div style="margin-bottom: 10px;" v-for="(item, index) in printModel.newNoteArr" :key="index">
            <span>
              <i>* {{item.content}}</i>
            </span>
          </div>
          </div>
          
        </div>
          <div style="height: 10px"></div>
          <div>
            <div style="float: left">
              Quality Assurance- <span style="font-size:28px;font-family: 'Comic Sans MS', cursive;">{{ printModel.formData.updateName }}</span>
            </div>
            <div style="float: right;margin-right: 80px;">Date: {{ formatTime(printModel.formData.updateTime,"yyyy-MM-dd") }}</div>
          </div>
        <div style="height: 20px;clear:both;"></div>
        <div style="font-size: 14px;">
              <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
            </div>

            <!-- <div style="height: 10px"></div> -->
            <div style="text-align: center">
              <b
                ><u>
                  539 Parrott Street San Jose CA, 95112 Tel: 408-287-7871
                  Fax:408-287-8838</u
                ></b
              >
            </div>
          <!-- <div style="height: 20px"></div> -->
          <div>
          </div>
          <FooterSigner style="padding-right: 5px;"></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
import base from "../../common/base";
export default {
  name: "SHXTO",
  props: ["printModel"],
  components: { FooterSigner },
  data() {
    return {
      loginName: localStorage.getItem("loginUserNameKey"),
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.inclNameArr.length,
        this.printModel.commonNameArr.length,
        this.printModel.botanicalNameArr.length, 
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    }, 
    isShowTable(){
      if(this.printModel.form.tableInput1 || this.printModel.form.tableInput2 || this.printModel.form.tableInput3 || this.printModel.form.tableInput4 || this.printModel.form.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {
    getTime() {
      return base.methods.getLocalTime(-5);
    },
    formatDate(time) {
      return base.methods.formatDate({ date: time });
    },
    formatTime(time,format){
      if(time){
        return base.methods.formatTime(time,format);
      }
      else{
        return base.methods.formatTime(new Date(),format);
      }
    },
    isSingle(){
        var count = 0;
        var name = "";
        var arr = [];
        if(this.printModel.commonNameArr.length>=1){
            name="Common Name";
            count = count+1;
            arr = this.printModel.commonNameArr;
        }
        if(this.printModel.inclNameArr.length>=1){
            name="INCI name";
            count = count+1;
            arr = this.printModel.inclNameArr;
        }
        if(this.printModel.botanicalNameArr.length>=1){
            name="Botanical Name";
            count = count+1;
            arr = this.printModel.botanicalNameArr;
        }
        if(this.printModel.partUsedArr.length>=1){
            name="Part Used";
            count = count+1;
            arr = this.printModel.partUsedArr;
        }
        let res =  {count,name,arr};
        return res
      },
    checkNumber(number){
      let firstStr=(number + "").substring(0,1)
      if(firstStr=='<' || firstStr=='≤'){
        number = number.substring(1)
      }
      var numReg = /^[0-9]+([.]{1}[0-9]+){0,1}$/
      var numRe = new RegExp(numReg)
      if (!numRe.test(number)) {
        return false
      }
      return true
    }
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if (userInfo) {
      localStorage.setItem("loginUserNameKey", userInfo.username);
    }
  },
};
</script>

<style scoped>
b {
  font-size: 14px;
}
.tab {
  margin-top: 5px;
}
table,
p {
  margin: 0px;
  padding: 0px;
}
.AnalysisTable {
  width: 80%;
}
.AnalysisTable tr {
  line-height: 30px;
}
.AnalysisTable tr td {
  border: 1px solid gray;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.FontWeight {
  font-weight: bold;
}
</style>